<template>
  <div class="mb-4">
    <page-title title="Dashboard" />
    <highlights-articles
      :created-by-me="articlesCreatedByMe"
      :recently-seen="articlesRecentlySeen"
      :recently-updated="articlesRecentlyUpdated"
    />
    <div class="flex flex-row px-12 m-auto mt-8 justify-between">
      <articles-top :articles="articlesTop" />
      <articles-flop :articles="articlesFlop" />
    </div>
  </div>
  <evolution-chart v-if="isReady" class="mx-12 mb-14 mt-4" :data="chartData">
    <template #header>
      <div class="flex align-center">
        <h1 class="text-orange font-semibold">
          EVOLUTION OF TOTAL ARTICLE VIEWS
          <span class="pl-2 text-2xl font-bold text-black">{{ totalView }}</span>
        </h1>
      </div>
    </template>
  </evolution-chart>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import HighlightsArticles from '@/components/HighlightsArticles'
import ArticlesFlop from '@/components/ArticlesFlop'
import ArticlesTop from '@/components/ArticlesTop'
import EvolutionChart from '@/components/charts/EvolutionChart'
import { useAxios } from '@/services/api'
export default {
  name: 'Dashboard',

  components: {
    EvolutionChart,
    ArticlesTop,
    ArticlesFlop,
    HighlightsArticles,
    PageTitle,
  },
  data() {
    return {
      isReady: false,
      articlesRecentlyUpdated: [],
      articlesRecentlySeen: [],
      articlesCreatedByMe: [],
      articlesTop: [],
      articlesFlop: [],
      totalView: null,
      chartData: {
        labels: [],
        datasets: [],
      },
    }
  },
  async mounted() {
    const axios = useAxios()
    const result = await axios.get('/api/statistics')
    this.articlesRecentlyUpdated = result.data.recently_updates
    this.articlesCreatedByMe = result.data.created_by_me
    this.articlesRecentlySeen = result.data.recently_seen

    Object.values(result.data.top).forEach((articles) => {
      this.articlesTop.push(...articles)
    })

    Object.values(result.data.flop).forEach((articles) => {
      this.articlesFlop.push(...articles)
    })

    // Chart
    Object.keys(result.data.views_articles).forEach((date) => {
      this.chartData.labels.unshift(date)

      if (!this.chartData.datasets[0]) {
        this.chartData.datasets[0] = []
      }

      const sumValues = Object.values(result.data.views_articles[date]).reduce((a, b) => a + b)
      this.chartData.datasets[0].unshift(sumValues)
    })

    this.isReady = true
  },
}
</script>

<style>
.status-unpublished {
  @apply bg-orange;
}

.status-published {
  @apply bg-green;
}

.status-draft {
  @apply bg-black;
}

.status-to_translate {
  @apply bg-gray-400;
}

.status-to_validate {
  @apply bg-blue-500;
}
</style>
