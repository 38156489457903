<template>
  <base-modal title="Enter a Youtube video ID" :open="true" @close="close">
    <template #content>
      <div class="container mx-auto px-9 py-4">
        <label>ID :</label>
        <input
          v-model="id"
          type="text"
          class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          @keyup.enter="submit(id)"
        />
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="
          mt-3
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-orange
          text-base
          font-semibold
          text-white
          hover:bg-darkgray
          sm:mt-0 sm:w-auto sm:text-sm
        "
        @click="submit(id)"
      >
        Continue
      </button>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          px-4
          py-2
          bg-white
          text-base
          font-semibold
          text-gray-600
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
          sm:ml-3 sm:w-auto sm:text-sm
        "
        @click="close"
      >
        Cancel
      </button>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/modals/BaseModal'
import { ref } from 'vue'

const emits = defineEmits(['apply', 'close-modal'])

const id = ref('')

const close = () => {
  emits('close-modal')
}

const submit = (id) => {
  if (id) {
    emits('apply', { slug: 'youtube', payload: id })
  }
}
</script>
