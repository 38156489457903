import { useFetch, useCreate } from '@/services/api'

const solutions = {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },

  getters: {
    getSolutions: (state) => {
      return state.all
    },
  },

  mutations: {
    setSolutions(state, solutions) {
      state.all = solutions
    },
    resetSolutions(state) {
      state.all = []
    },
    setStatusLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async fetchSolutions({ commit }) {
      commit('resetSolutions')
      commit('setStatusLoading', true)

      try {
        const solutionsResponse = await useFetch('solutions')
        commit('setSolutions', solutionsResponse.data)
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
  },
}

export default solutions
