<template>
  <div>
    <link-modal
      v-if="modals.link.open"
      :open="modals.link.open"
      @apply="insertLinkPayload"
      @close-modal="modals.link.open = false"
    />
    <div id="editor" class="mt-4" @paste="onPaste"></div>
    <media-manager
      v-if="modals.media.open"
      :open="modals.media.open"
      size="sm:max-w-6xl"
      type="image"
      @files="processFilesSelected"
      @close-modal="modals.media.open = false"
    />
    <formula-modal
      v-if="modals.latex.open"
      :open="modals.latex.open"
      @apply="insertLatex"
      @close-modal="modals.latex.open = false"
    />
    <youtube-modal
      v-if="modals.youtube.open"
      :open="modals.youtube.open"
      @apply="insertYoutube"
      @close-modal="modals.youtube.open = false"
    />
  </div>
</template>
<script>
import Editor from '@toast-ui/editor'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import { notify } from '@kyvg/vue3-notification'
import '@toast-ui/editor/dist/toastui-editor.css'
import MediaManager from '@/components/medias/MediaManager'
import LinkModal from '@/components/modals/LinkModal'
import FormulaModal from '@/components/modals/FormulaModal'
import YoutubeModal from '@/components/modals/YoutubeModal'
export default {
  name: 'Badoum',
  components: { MediaManager, LinkModal, FormulaModal, YoutubeModal },
  props: {
    markdown: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modals: {
        latex: {
          open: false,
          value: null,
        },
        link: {
          open: false,
          value: null,
        },
        media: {
          open: false,
          value: null,
        },
        youtube: {
          open: false,
          value: null,
        },
      },
    }
  },
  mounted() {
    const target = document.querySelector('#editor')

    window.editor = new Editor({
      el: target,
      usageStatistics: false,
      height: 'auto',
      initialValue: this.markdown || '',
      initialEditType: 'wysiwyg',
      previewStyle: 'tab',
      previewHighlight: false,
      hideModeSwitch: false,
      useCommandShortcut: true,
      placeholder: 'Enter the body here...',
      plugins: [this.pluginLatex, this.pluginYoutube],
      toolbarItems: [['heading', 'bold', 'italic'], ['hr', 'quote'], ['ul', 'ol'], ['table']],
      autofocus: false,
    })

    this.initLink()
    this.initLatex()
    this.initMedia()
    this.initYoutube()
    this.initConfigUpload()
    this.initChangeMode()
  },
  unmounted() {
    window.editor.destroy()
    delete window.editor
  },
  methods: {
    // onPaste(event) {
    //   event.preventDefault()
    //   console.log('onPaste', event.target.value, event.clipboardData.getData('text'), event)
    // },
    initChangeMode() {
      window.editor.insertToolbarItem(
        { groupIndex: 4, itemIndex: 0 },
        {
          name: 'Switch',
          tooltip: 'Switch to Markdown editor',
          command: 'switch-mode',
          text: 'MD',
          className: 'toastui-editor-toolbar-icons',
          style: {
            backgroundImage: 'none',
          },
        },
      )
      const switchMode = function () {
        if (window.editor.getCurrentModeEditor().editorType === 'markdown') {
          window.editor.changeMode('wysiwyg')
        } else {
          window.editor.changeMode('markdown')
        }
      }
      window.editor.addCommand('wysiwyg', 'switch-mode', () => {
        return switchMode()
      })

      window.editor.addCommand('markdown', 'switch-mode', () => {
        return switchMode()
      })
    },
    initLatex() {
      window.editor.insertToolbarItem(
        { groupIndex: 3, itemIndex: 3 },
        {
          name: 'Latex',
          tooltip: 'Open latex',
          command: 'media-latex',
          text: 'Latex',
          className: 'toastui-editor-toolbar-icons',
          style: {
            backgroundImage: 'none',
          },
        },
      )

      window.editor.addCommand('wysiwyg', 'media-latex', () => {
        this.modals.latex.open = true
      })

      window.editor.addCommand('markdown', 'media-latex', () => {
        this.modals.latex.open = true
      })
    },
    initLink() {
      window.editor.insertToolbarItem(
        { groupIndex: 3, itemIndex: 2 },
        {
          name: 'Links',
          tooltip: 'Open links',
          command: 'media-links',
          text: '',
          className: 'link toastui-editor-toolbar-icons',
        },
      )

      window.editor.addCommand('wysiwyg', 'media-links', () => {
        this.modals.link.open = true
      })

      window.editor.addCommand('markdown', 'media-links', () => {
        this.modals.link.open = true
      })
    },
    initMedia() {
      window.editor.insertToolbarItem(
        { groupIndex: 3, itemIndex: 1 },
        {
          name: 'Image',
          tooltip: 'Open image manager',
          command: 'image-manager',
          text: '',
          className: 'image toastui-editor-toolbar-icons',
        },
      )

      window.editor.addCommand('wysiwyg', 'image-manager', () => {
        this.modals.media.open = true
      })
      window.editor.addCommand('markdown', 'image-manager', () => {
        this.modals.media.open = true
      })
    },
    initYoutube() {
      window.editor.insertToolbarItem(
        { groupIndex: 3, itemIndex: 4 },
        {
          name: 'youtube',
          tooltip: 'Youtube',
          command: 'youtube-link',
          text: 'YT',
          className: 'fa fa-link youtube toastui-editor-toolbar-icons',
          style: {
            backgroundImage: 'none',
          },
        },
      )

      window.editor.addCommand('wysiwyg', 'youtube-link', () => {
        this.modals.youtube.open = true
      })
      window.editor.addCommand('markdown', 'youtube-link', () => {
        this.modals.youtube.open = true
      })
    },
    initConfigUpload() {
      window.editor.addHook('addImageBlobHook', async (blob, e) => {
        try {
          notify({
            type: 'info',
            duration: -1,
            title: 'Image uploading...',
          })

          const image = await this.$store.dispatch('medias/postMedia', {
            type: 'image',
            file: blob,
          })

          this.$store.dispatch('images/fetchImage', { id: image.id })

          notify({
            clean: true,
          })
          notify({
            type: 'success',
            duration: 6000,
            title: 'Image uploaded successfully',
          })

          return e(image.field_image_url)
        } catch (e) {
          notify({
            clean: true,
          })
          notify({
            type: 'error',
            duration: -1,
            title: 'Error while uploading image',
            text: e.message(),
          })
        }
      })
    },
    pluginLatex() {
      const toHTMLRenderers = {
        latex(node) {
          const macros = {
            '\\f': '#1f(#2)',
          }
          const string = katex.renderToString(String.raw`${node.literal}`, {
            throwOnError: true,
            macros,
          })

          return [
            { type: 'openTag', tagName: 'div', attributes: { class: 'latex' }, outerNewLine: true },
            { type: 'html', content: string },
            { type: 'closeTag', tagName: 'div', outerNewLine: true },
          ]
        },
      }

      return { toHTMLRenderers }
    },
    processFilesSelected(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        this.$store.dispatch('images/fetchImage', { id: file.id })

        window.editor.exec('addImage', {
          imageUrl: file.url,
          altText: file?.field_media_image?.data?.meta?.alt,
        })
      }
    },
    insertLatex(data) {
      // Uncomment for 3.1.4 when getHTML() bug will be fixed
      // window.editor.exec('customBlock', { info: 'latex' })
      // window.editor.insertText(data.payload)
      this.updateMarkdown('latex', data.payload)

      this.modals.latex.open = false
    },
    insertLinkPayload(data) {
      this.insertURL({
        url: data.payload.url,
        name: data.payload.name,
        newLine: data.payload.newLine,
      })
    },
    pluginYoutube() {
      const toHTMLRenderers = {
        youtube(node) {
          const html = `<a href="https://youtu.be/${node.literal}" target="_blank"><img src="https://i.ytimg.com/vi/${node.literal}/0.jpg" alt="${node.literal}" /></a>`

          return [
            {
              type: 'openTag',
              tagName: 'div',
              attributes: { class: 'yt-view' },
              outerNewLine: true,
            },
            { type: 'html', content: html },
            { type: 'closeTag', tagName: 'div', outerNewLine: true },
          ]
        },
      }

      return { toHTMLRenderers }
    },
    insertYoutube(data) {
      // Uncomment for 3.1.4 when getHTML() bug will be fixed
      // window.editor.exec('customBlock', { info: 'youtube' })
      // window.editor.insertText(data.payload)
      this.updateMarkdown('youtube', data.payload)

      this.modals.youtube.open = false
    },
    // link: {name: string, url: string, newLine: bool}
    insertURL(link) {
      const scrollTop = document.getElementById('article-container-hfull').scrollTop
      const selection = window.editor.getSelection()
      const block = `[customblock id="${this.makeId(10)}"/]`

      if (link.newLine) {
        window.editor.insertText(block + '\n')
      } else {
        window.editor.insertText(block)
      }

      let markdown = window.editor.getMarkdown()

      markdown = markdown.replace(block, `[${link.name?.trim()}](${link.url?.trim()})`)

      window.editor.setMarkdown(markdown)

      window.editor.setSelection(selection[0] + 1)
      const newSelection = window.editor.getRangeInfoOfNode()
      window.editor.setSelection(newSelection.range[1])

      document.getElementById('article-container-hfull').scrollTop = scrollTop

      this.modals.link.open = false
    },
    updateMarkdown(type, content) {
      const scrollTop = document.getElementById('article-container-hfull').scrollTop
      const [start, end] = window.editor.getSelection()
      const block = `[customblock id="${this.makeId(10)}"/]`

      window.editor.insertText(block)

      let markdown = window.editor.getMarkdown()
      const newBlock = String.raw`${content}`
      const newContent = '\n$$$' + type + '\n' + newBlock + '\n$$$\n'

      markdown = markdown.replace(block, newContent)
      window.editor.setMarkdown(markdown)

      window.editor.setSelection(start + 1)
      const newSelection = window.editor.getRangeInfoOfNode()
      window.editor.setSelection(newSelection.range[1])

      document.getElementById('article-container-hfull').scrollTop = scrollTop
    },
    makeId(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    },
  },
}
</script>

<style lang="scss">
.toastui-editor-popup-body {
  li[data-level='1'],
  li[data-level='5'],
  li[data-level='6'],
  li[data-level='6'] {
    display: none;
  }
}
.toastui-editor-defaultUI {
  border: none;

  .ProseMirror {
    padding: 0;
    font-size: 1rem;
    .toastui-editor-md-table,
    .toastui-editor-md-marked-text {
      color: #666;
    }
    .toastui-editor-md-link-desc {
      color: #c9510d;
    }
    img.ProseMirror-separator {
      display: inline-block;
      margin: 0 !important;
    }
  }
}
.toastui-editor-ww-container {
  background: transparent;
}

#editor {
  width: 100%;
}

#editor,
#preview {
  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 700;
    border: none;
    margin: 0 0 1.5rem;
    padding: 0;
    box-sizing: border-box;
  }

  img {
    margin: 2rem auto 1rem;
  }

  p {
    margin: 0 0 1rem;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    padding: 0;
  }

  ul,
  ol {
    p {
      margin: 0;
    }
  }

  ul > li::before {
    background-color: #434343;
  }

  table {
    border: 1px solid #c9510d;
    width: 100%;
    margin-bottom: 30px;
    table-layout: fixed;

    th {
      background-color: #c9510d;
      color: #fff;
      padding: 10px;
    }

    td {
      border: 1px solid #c9510d;
      padding: 10px;
    }
  }

  hr {
    border-color: #434343;
  }

  blockquote {
    font-size: 1.6rem;
    line-height: 2.5rem;
    border-left: 2px solid #c9510d;
    margin-left: 19px;
    padding-left: 30px;
    margin-bottom: 30px;
    p {
      margin: 0;
      color: rgb(34, 34, 34);
    }
  }
  a {
    color: #c9510d;
    text-decoration: none;
  }
}

.toastui-editor-toolbar {
  position: sticky;
  top: 0;
  z-index: 21;
}

.toastui-editor-md-tab-container {
  display: none !important;
}

.toastui-editor-defaultUI-toolbar {
  border-radius: 5px !important;
  border: 1px solid #dbd4ea !important;
}

.toastui-editor-mode-switch {
  display: none !important;
}
.toastui-editor-md-link {
  color: #666;
}
.toastui-editor-md-delimiter {
  color: #03f;
}
/*Plugin edition*/
.toastui-editor-custom-block {
  .toastui-editor-custom-block-view {
    span {
      color: #222;
    }
    button {
      background-color: #f7f9fc;
      width: 24px;
      height: 24px;
      background-size: 45px 45px;
    }
  }
  .toastui-editor-custom-block-editor {
    background: #f7f9fc;
    color: #222;
    border: solid 1px #dbd4ea;
  }
  .toastui-editor-custom-block-view,
  .toastui-editor-custom-block-editor {
    padding: 1rem;
    border-radius: 5px !important;
  }
  :hover {
    .toastui-editor-custom-block-view {
      border: solid 1px #dbd4ea;
    }
    .tool {
      display: block;
    }
  }
}
</style>
