<template>
  <div class="px-8">
    <div
      class="
        flex
        items-center
        p-4
        mt-4
        m-auto
        bg-white
        rounded-xl
        border-b border-gray-200
        justify-between
      "
    >
      <div class="flex">
        <select-input
          v-model="currentStatus"
          class="w-44"
          :input-class="inputClass(currentStatus, 'status')"
          :values="statusValues"
        />
        <select-input
          v-model="currentTranslation"
          class="w-44"
          :input-class="inputClass(currentTranslation, 'translation')"
          :values="translationValues"
        >
          <template #select="{ label }">
            <div class="flex border-l border-gray-300 pl-2">
              {{ label }}
              <icon-base class="ml-2 w-4 h-4 fill-current" width="20" height="20">
                <icon-arrow-down />
              </icon-base>
            </div>
          </template>
        </select-input>
        <select-input
          v-model="currentCategory"
          class="w-44 ml-2"
          :input-class="inputClass(currentCategory)"
          :values="categoryValues"
        />
        <select-input
          v-model="currentAuthor"
          class="w-44 ml-2"
          :input-class="inputClass(currentAuthor)"
          :values="authorsValues"
        />

        <button
          class="ml-3 mt-2.5 h-5 w-5 rounded-xl bg-gray-200 hover:bg-gray-500"
          type="reset"
          @click="reset"
        >
          <icon-base class="h-3 w-3 ml-1" height="20" width="20">
            <icon-cross />
          </icon-base>
        </button>
      </div>
      <div class="flex items-center font-semibold">
        <label
          for="newer_first_sort"
          class="cursor-pointer flex gap-4"
          :class="{ hidden: currentSort === 'changed' }"
        >
          <p class="text-av mt-0.5 text-gray-500">Sort by date, newer first</p>
          <p>
            <img
              src="../assets/left-arrow.png"
              class="block w-3 h-3"
              style="transform: rotate(90deg)"
            />
            <img
              src="../assets/left-arrow.png"
              class="block w-3 h-3"
              style="transform: rotate(270deg)"
            />
          </p>
        </label>
        <input
          id="newer_first_sort"
          v-model="currentSort"
          name="sort_filter"
          type="radio"
          class="hidden"
          value="changed"
        />

        <label
          for="older_first_sort"
          class="cursor-pointer flex gap-4"
          :class="{ hidden: currentSort === '-changed' }"
        >
          <p class="text-av mt-0.5 text-gray-500">Sort by date, older first</p>
          <p>
            <img
              src="../assets/left-arrow.png"
              class="block w-3 h-3"
              style="transform: rotate(90deg)"
            />
            <img
              src="../assets/left-arrow.png"
              class="block w-3 h-3"
              style="transform: rotate(270deg)"
            />
          </p>
        </label>
        <input
          id="older_first_sort"
          v-model="currentSort"
          name="sort_filter"
          type="radio"
          class="hidden"
          value="-changed"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import statuses from '@/config/statusesArticles'
import languages from '@/config/languagesArticles'
import SelectInput from '@/components/inputs/SelectInput'

const store = useStore()

const inputClass = computed(() => (value, type) => {
  const filterClass = value === 'all' ? 'text-gray-400' : ''

  if (type === 'status') {
    return `${filterClass} rounded-none rounded-l border-r-0 `
  } else if (type === 'translation') {
    return `${filterClass}  rounded-none rounded-r border-l-0 pl-0`
  }

  return filterClass
})

const currentStatus = computed({
  get: () => store.state.articles.filters.status,
  set: (value) => store.dispatch('articles/setStatusFilter', value),
})
const statusValues = computed(() => {
  return statuses.map((status) => {
    return { value: status.key, label: status.name }
  })
})

const currentTranslation = computed({
  get: () => store.state.articles.filters.translation,
  set: (value) => store.dispatch('articles/setTranslationFilter', value),
})
const translationValues = computed(() => {
  return languages.map((language) => {
    return { value: language.key, label: language.name }
  })
})

const currentAuthor = computed({
  get: () => store.state.articles.filters.author,
  set: (value) => store.dispatch('articles/setAuthorFilter', value),
})

const currentCategory = computed({
  get: () => store.state.articles.filters.category,
  set: (value) => store.dispatch('articles/setCategoryFilter', value),
})

const currentSort = computed({
  get: () => store.state.articles.sort,
  set: (value) => store.dispatch('articles/setSort', value),
})

const categories = computed(() => store.state.categories.all)
const categoryValues = computed(() => {
  return [{ value: 'all', label: 'All categories' }].concat(
    categories.value.map((category) => {
      return { value: category.id, label: category.name }
    }),
  )
})

const authors = computed(() => store.state.authors.all)
const authorsValues = computed(() => {
  return [{ value: 'all', label: 'All authors' }].concat(
    authors.value.map((author) => {
      return { value: author.id, label: author.display_name }
    }),
  )
})

const reset = () => store.dispatch('articles/resetFilters')

onMounted(async () => {
  await store.dispatch('categories/fetchCategories')
  await store.dispatch('authors/fetchAuthors')
})
</script>
