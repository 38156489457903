<template>
  <div class="flex justify-end mb-10">
    <div class="w-full">
      <div>
        <page-title title="Categories" />
      </div>
      <div class="flex flex-col px-12 m-auto mt-3">
        <div v-if="isProcessing" class="flex mt-10 justify-center">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div v-else>
          <category-nested-draggable :categories="categories" />
        </div>
      </div>
    </div>
    <category-articles-slide-over />
  </div>

  <category-slide-over
    :open="modals.slideover.open"
    :parent-id="parentId"
    :category-id="categoryId"
    :is-removable="isRemovable"
    @close-slide-over="closeSlideOver"
    @fetchCategories="fetchCategories()"
  />

  <delete-category />
</template>

<script>
import { useAxios } from '@/services/api'

import PageTitle from '@/components/PageTitle'
import CategorySlideOver from '@/components/Slideover/CategorySlideOver'
import DeleteCategory from '@/components/modals/DeleteCategory'
import CategoryArticlesSlideOver from '@/components/Slideover/CategoryArticlesSlideOver'

export default {
  components: { CategoryArticlesSlideOver, PageTitle, CategorySlideOver, DeleteCategory },
  data() {
    return {
      categories: [],
      parentId: null,
      categoryId: null,
      isProcessing: null,
      modals: {
        deletion: {
          open: false,
        },
        slideover: {
          open: false,
        },
      },
    }
  },
  computed: {
    axios() {
      return useAxios()
    },
    hasChildren() {
      if (!this.categoryId) {
        return true
      }

      let category = null

      const findCategory = (idCategory, categories) => {
        categories.forEach((cat) => {
          if (cat.id === idCategory) {
            category = cat
          } else if (Array.isArray(cat.elements) && cat.elements.length) {
            return findCategory(idCategory, cat.elements)
          }
        })
      }

      findCategory(this.categoryId, this.categories)

      return Boolean(
        category.elements && Array.isArray(category.elements) && category.elements.length,
      )
    },
    isRemovable() {
      return this.categoryId && !this.hasChildren
    },
  },
  async mounted() {
    this.isProcessing = true
    await this.fetchCategories()

    this.$emitter.on('emitSlide', this.openSlideEdit)
    this.$emitter.on('emitCreateCategory', this.openSlide)
    this.$emitter.on('emitFetchCategories', this.fetchCategories)
    this.$emitter.on('dragCategories', async () => {
      await this.axios.patch('/api/taxonomies_sorted/categories', this.categories)
    })
    this.isProcessing = false
  },
  methods: {
    async fetchCategories() {
      this.categories = []
      const result = await this.axios.get('/api/taxonomies_sorted/categories')
      this.categories.push(...result.data)
    },
    openSlide(idParent, idCategory) {
      this.parentId = idParent
      this.categoryId = idCategory
      this.modals.slideover.open = true
    },
    closeSlideOver() {
      this.modals.slideover.open = false
      this.parentId = null
      this.categoryId = null
    },
    async openSlideEdit(event) {
      await this.$store.dispatch('categories/fetchCategory', {
        id: event.categoryId,
      })

      if (event.destination === 'sub') {
        this.openSlide(event.categoryId, null)
      } else {
        await this.$store.dispatch('categories/fetchCategoryTranslations', {
          id: event.categoryId,
        })

        this.openSlide(null, event.categoryId)
      }
    },
  },
}
</script>
