import Cookies from 'js-cookie'
import axios from 'axios'

class AuthService {
  login(code) {
    const redirect_url = `${process.env.VUE_APP_URL}/login`

    const data = new FormData()
    data.append('client_id', process.env.VUE_APP_API_CLIENT_ID)
    data.append('grant_type', 'authorization_code')
    data.append('redirect_uri', redirect_url)
    data.append('code', code)

    return axios.post(process.env.VUE_APP_AUTH_URL + '/oauth/token', data).then((response) => {
      if (response.data && response.data.access_token && response.data.expires_in) {
        const now = new Date()
        now.setTime(now.getTime() + 1 * response.data.expires_in * 1000)
        Cookies.set('token', response.data.access_token, { expires: now })
      }

      return response.data
    })
  }

  logout() {
    // Remove token
    Cookies.remove('token')
    Cookies.remove('user')
  }
}

export default new AuthService()
