<template>
  <div class="text-right">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded"
        >
          <img src="../assets/user.jpg" alt="profile_picture" class="rounded-full" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="
            absolute
            right-0
            w-36
            mt-1
            origin-top-right
            divide-y divide-gray-100
            rounded-md
            focus:outline-none
            z-10
          "
        >
          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
              <button
                type="button"
                :class="[
                  active ? 'bg-gray-100' : 'bg-white',
                  'group font-semibold rounded flex items-center w-full px-4 py-3 ring-2 ring-black ring-opacity-5 text-sm',
                ]"
                @click="logout"
              >
                Logout
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import AuthService from '@/services/auth.service'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  methods: {
    logout() {
      AuthService.logout()
      window.location.href = `${process.env.VUE_APP_API_URL}/user/logout`
    },
  },
}
</script>
