import 'tailwindcss/tailwind.css'
import '../public/global.css'

import App from '@/App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { createApp } from 'vue'
import router from '@/router'
import store from '@/store'
import Notifications from '@kyvg/vue3-notification'
import mitt from 'mitt'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faImage,
  faFile,
  faSpinner,
  faTrash,
  faFileUpload,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { upperFirst } from 'lodash-es'
import { camelCase } from 'lodash-es'
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar'
import vClickOutside from 'click-outside-vue3'
library.add(faSpinner, faImage, faFile, faFileUpload, faTrash, faLink)

const app = createApp(App)
const emitter = mitt()

store.commit('articles/initStore')
store.commit('categories/initStore')

app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(Notifications)
app.use(vClickOutside)
app.config.globalProperties.$emitter = emitter

import CategoryNestedDraggable from '@/components/dragdrop/CategoryNestedDraggable'

app
  .use(SetupCalendar, {})
  .component('Calendar', Calendar)
  .component('DatePicker', DatePicker)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('category-nested-draggable', CategoryNestedDraggable)

// Auto Import
const importComponents = (components) => {
  components.keys().forEach((fileName) => {
    const componentConfig = components(fileName)
    const componentName = upperFirst(
      camelCase(
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, ''),
      ),
    )

    app.component(componentName, componentConfig.default || componentConfig)
  })
}
// IMPORT LAYOUTS AUTO
const requireLayoutComponents = require.context('./layouts', false, /[A-Z]\w+Layout\.(vue|js)$/)
importComponents(requireLayoutComponents)

// Import Icon
const requireIconsComponent = require.context('./components/icons', false, /^\.\/Icon[A-Z]\w+.vue$/)
importComponents(requireIconsComponent)

app.mount('#app')
