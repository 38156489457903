<template>
  <div class="px-12 mt-4">
    <section class="bg-white rounded-xl overflow-hidden">
      <div class="flex justify-between items-center border-b">
        <h1 class="text-orange text-smaller font-semibold py-4 px-6">HIGHLIGHTS ARTICLES</h1>

        <div class="flex mr-6 space-x-4 text-smaller">
          <div
            ref="updatedDiv"
            class="item cursor-pointer py-4"
            :class="{ 'item-selected': currentWindow === 'updated' }"
            @click="currentWindow = 'updated'"
          >
            <p class="font-semibold">Recently updated</p>
          </div>
          <div
            ref="seenDiv"
            class="item cursor-pointer py-4"
            :class="{ 'item-selected': currentWindow === 'seen' }"
            @click="currentWindow = 'seen'"
          >
            <p class="font-semibold">Recently seen</p>
          </div>
          <div
            ref="byMeDiv"
            class="item cursor-pointer py-4"
            :class="{ 'item-selected': currentWindow === 'created' }"
            @click="currentWindow = 'created'"
          >
            <p class="font-semibold">Created by me</p>
          </div>
        </div>
      </div>
      <!-- Faire varier l'envoi de 'articles' en fonction de la current window -->
      <article-list :articles="articles" :translations="true" />
    </section>
  </div>
</template>

<script>
import ArticleList from '@/components/ArticleList'
import { ref } from 'vue'

export default {
  components: {
    ArticleList,
  },
  props: {
    recentlySeen: {
      type: Array,
      required: [],
      default: () => [],
    },
    recentlyUpdated: {
      type: Array,
      required: [],
      default: () => [],
    },
    createdByMe: {
      type: Array,
      required: [],
      default: () => [],
    },
  },
  data() {
    return {
      currentWindow: 'updated',
    }
  },
  computed: {
    articles() {
      switch (this.currentWindow) {
        case 'created':
          return this.createdByMe
        case 'seen':
          return this.recentlySeen
      }

      return this.recentlyUpdated
    },
  },
}
</script>

<style scoped>
.item {
  @apply text-orange;
  @apply border-b-2;
  @apply border-transparent;
  @apply hover:border-darkgray;
}

.item-selected {
  @apply text-black;
  @apply border-b-2;
  @apply border-black;
}
</style>
