<template>
  <div class="h-screen flex overflow-hidden font-poppins">
    <nav-bar />
    <main class="flex-1 relative overflow-y-auto focus:outline-none bg-lightgray">
      <search-bar v-if="hasSearch" />
      <slot />
    </main>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import NavBar from '@/components/NavBar'
import SearchBar from '@/components/SearchBar'
import { computed } from 'vue'

const route = useRoute()
const hasSearch = computed(() => {
  return route.meta.search || false
})
</script>
