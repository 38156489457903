import Cookies from 'js-cookie'
import Kitsu from 'kitsu'
import axios from 'axios'
import AuthService from '@/services/auth.service'
const slugify = require('slugify')

const token = Cookies.get('token') ?? null
const options = {
  baseURL: process.env.VUE_APP_API_URL,
  camelCaseTypes: false,
  headers: {
    Authorization: 'Bearer ' + token,
  },
}

const kitsu = new Kitsu(options)
kitsu.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      AuthService.logout()
    }

    return Promise.reject(error)
  },
)
const getType = (ressource) => {
  switch (ressource) {
    case 'image':
    case 'document':
      return `media--${ressource}`
    case 'articles-categories':
    case 'categories':
      return 'taxonomy_term--categories'
    case 'articles-solutions':
    case 'solutions':
      return 'taxonomy_term--solutions'
    case 'article':
      return 'node--article'
    case 'link':
      return 'mink_link--mink_link'
  }

  return ressource
}

const getPathFromRessource = (ressource, id, lang) => {
  let url = ''
  switch (ressource) {
    case 'dataset':
      url = `/jsonapi/node/article/${id}/relationships/field_dataset`
      id = null
      break
    case 'image':
      url = `/jsonapi/media/image`
      break
    case 'document':
      url = `/jsonapi/media/document`
      break
    case 'articles-categories':
      url = `/jsonapi/node/article/${id}/relationships/field_categories`
      id = null
      break
    case 'articles-solutions':
      url = `/jsonapi/node/article/${id}/relationships/field_solutions`
      id = null
      break
    case 'article':
      url = `/jsonapi/node/article`
      break
    case 'user':
      url = '/jsonapi/user/user'
      break
    case 'categories':
      url = '/jsonapi/taxonomy_term/categories'
      break
    case 'solutions':
      url = '/jsonapi/taxonomy_term/solutions'
      break
    case 'link':
      url = '/jsonapi/mink_link/mink_link'
      break
    default:
      url = ressource
      break
  }

  if (id) {
    url = `${url}/${id}`
  }

  if (lang && lang !== 'en') {
    return `/${lang}${url}`
  }

  return url
}

async function useFetch(ressource, params, id, lang) {
  try {
    return kitsu.request({
      method: 'GET',
      type: getType(ressource),
      url: getPathFromRessource(ressource, id, lang),
      params,
    })
  } catch (e) {
    console.error(e)

    throw e
  }
}

async function useGet(ressource, id, params, lang) {
  try {
    return kitsu.request({
      method: 'GET',
      type: getType(ressource),
      url: getPathFromRessource(ressource, id, lang),
      params,
    })
  } catch (e) {
    console.error(e)

    throw e
  }
}

const readFile = async (file) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsArrayBuffer(file)
  })
}

async function postMedia({ file, type }) {
  const data = await readFile(file)
  const response = await axios({
    method: 'post',
    url: `${process.env.VUE_APP_API_URL}/jsonapi/media/${type}/field_media_${type}`,
    data,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${slugify(file.name)}"`,
    },
  })

  return response.data
}

async function useCreate(ressource, body, params) {
  try {
    return kitsu.request({
      body: body,
      method: 'POST',
      params: params,
      type: getType(ressource),
      url: getPathFromRessource(ressource),
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

async function useUpdate(ressource, id, body, params) {
  try {
    let lang = body?.langcode

    return kitsu.request({
      method: 'PATCH',
      type: getType(ressource),
      url: getPathFromRessource(ressource, id, lang),
      body,
      params,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

async function useRemove(ressource, id, params) {
  try {
    return kitsu.request({
      method: 'DELETE',
      type: getType(ressource),
      url: getPathFromRessource(ressource, id),
      params,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

function useAxios() {
  const request = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  request.interceptors.response.use(
    (response) => {
      return response
    },
    (e) => {
      if (e.response.status === 401) {
        // remove all cookie
        AuthService.logout()
      }

      return e
    },
  )

  return request
}

export { useFetch, useGet, useCreate, useUpdate, useRemove, postMedia, useAxios }
