<template>
  <base-slide-over
    title="Select categories"
    :open="open"
    @close-slide-over="emit('close-slide-over')"
  >
    <template #content>
      <div class="absolute inset-0">
        <div class="flex flex-col h-full">
          <div class="flex h-28 items-center w-full border-t border-b border-gray-300 px-4 sm:px-6">
            <div class="flex items-center w-full h-full">
              <div class="flex w-full h-full py-6">
                <input
                  v-model="search"
                  class="
                    w-full
                    placeholder-gray-500
                    border-t border-b border-l border-gray-300
                    px-2
                    py-2
                    rounded-l
                    outline-none
                    text-sm
                    font-semibold
                  "
                  placeholder="Search for a category"
                />
                <button class="border-t border-b border-r border-gray-300 cursor-default">
                  <icon-base class="w-4 h-4 mr-2" height="20" width="20">
                    <icon-magnify />
                  </icon-base>
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full h-min mt-6 font-light px-4 sm:px-6 mb-4">
            Select categories
          </div>
          <div class="h-full w-full overflow-auto px-4 sm:px-6">
            <category-list
              :selected-ids="articleCategoriesId"
              :categories="displayedCategories"
              :editable="true"
              :hide-children="!!search"
              @toogle-category="toogleCategory"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <custom-button :classes="'bg-orange hover:bg-darkgray'" title="Validate" @click="validate" />
      <a
        class="text-gray-600 text-smaller font-semibold px-4 cursor-pointer"
        @click="$emit('close-slide-over')"
        >Cancel</a
      >
    </template>
  </base-slide-over>
</template>

<script setup>
import BaseSlideOver from '@/components/Slideover/BaseSlideOver'
import CustomButton from '@/components/CustomButton'
import { useStore } from 'vuex'
import CategoryList from '@/components/CategoryList'
import { onMounted, computed, ref, watch } from 'vue'

const store = useStore()
const emit = defineEmits(['close-slide-over', 'update:categories'])
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    default: () => [],
  },
})

let search = ref('')
let searchResult = ref([])
let searchTimeout = ref(null)
watch(search, () => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value)
  }
  searchTimeout.value = setTimeout(async () => {
    searchResult.value = await store.dispatch('categories/searchCategories', search.value)
  }, 500)
})
const displayedCategories = computed(() => {
  if (search.value) {
    return searchResult.value
  } else {
    return store.getters['categories/getRootCategories']
  }
})

let categoriesModel = ref([])
const initCategoriesModel = () => {
  categoriesModel.value = props.categories.concat([])
}
const articleCategoriesId = computed(() => categoriesModel.value.map((category) => category.id))
const toogleCategory = (categoryId) => {
  const categoryIndex = categoriesModel.value.findIndex((category) => category.id === categoryId)

  if (categoryIndex === -1) {
    categoriesModel.value.push(store.getters['categories/getById'](categoryId))
  } else {
    categoriesModel.value.splice(categoryIndex, 1)
  }
}

onMounted(async () => {
  // Load categories when DOM is mounted
  await store.dispatch('categories/fetchCategories')
  initCategoriesModel()
})
watch(
  () => props.open,
  () => {
    initCategoriesModel()
    search.value = ''
  },
)

const validate = () => {
  emit('update:categories', categoriesModel.value.concat([]))
  emit('close-slide-over')
}
</script>
