<template>
  <router-link
    :to="{ name: 'articles.show.views', params: { id: id, lang } }"
    class="flex flex-col border-b-2 border-transparent hover:border-darkgray text-orange"
    :class="{ currentLink: current }"
  >
    <p class="item mt-1 ml-1 font-semibold text-sm">Views</p>
    <p class="ml-1 font-semibold text-av text-gray-500">
      {{ views }}
    </p>
  </router-link>
</template>

<script>
export default {
  props: {
    views: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    current() {
      return this.$route.name === 'articles.show.views'
    },
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>

<style scoped>
.currentLink {
  @apply text-black;
  @apply border-darkgray;
}
</style>
