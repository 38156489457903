<template>
  <div class="dropdown relative font-semibold">
    <template v-if="isSearchInput">
      <input
        type="text"
        name=""
        :value="search"
        class="flex bg-white text-gray-700 border border-gray-300 rounded p-2 w-full"
        :class="inputClass"
        @input="$emit('update:search', $event.target.value)"
      />
    </template>
    <template v-else>
      <div
        class="
          flex
          bg-white
          text-gray-700
          border border-gray-300
          rounded
          items-center
          justify-between
          p-2
          cursor-pointer
        "
        :class="inputClass"
        @click="toogleSelect"
      >
        <slot name="select" :label="hasValue ? selectedValueLabel : placeholder">
          <slot v-if="hasValue" name="value" :value="selectedValue">
            {{ selectedValueLabel }}
          </slot>
          <slot v-else name="placeholder">
            <span class="text-gray-500">{{ placeholder }} </span>
          </slot>
          <icon-base class="ml-2 w-4 h-4 fill-current" width="20" height="20">
            <icon-arrow-down />
          </icon-base>
        </slot>
      </div>
    </template>
    <ul
      v-if="dropdownOpen"
      v-click-outside="toogleSelect"
      class="
        w-full
        dropdown-menu
        absolute
        border-l border-r border-b border-gray-300
        rounded-t
        bg-white
        overflow-auto
        max-h-80
        text-sm text-gray-700
      "
    >
      <li
        v-for="value in values"
        :key="value.value"
        class="flex items-center hover:bg-gray-200 h-10 cursor-pointer p-2"
        @click="select(value.value)"
      >
        <slot name="value" :value="value">
          <input v-if="multiple" :checked="isSelected(value.value)" type="checkbox" class="mr-2" />
          {{ value.label }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

const emit = defineEmits(['update:modelValue', 'update:search'])
const props = defineProps({
  modelValue: {
    validator: () => true,
    default: () => null,
  },
  search: {
    type: String,
    default: () => null,
  },
  values: {
    validator: () => true,
    default: () => null,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Select',
  },
  inputClass: {
    type: String,
    default: '',
  },
})

const dropdownOpen = ref(false)
const toogleSelect = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const isSearchInput = computed(() => {
  return props.search !== undefined && props.search !== null
})
const hasValue = computed(
  () =>
    (props.multiple && props.modelValue && props.modelValue.length > 0) ||
    (!props.multiple && props.modelValue),
)
const isSelected = computed(() => (value) => {
  return (
    (props.multiple &&
      props.modelValue &&
      props.modelValue.findIndex((selectedValue) => selectedValue === value) !== -1) ||
    (!props.multiple && props.modelValue === value)
  )
})
const selectedValue = computed(() => {
  if (props.multiple) {
    return props.values.filter((value) => isSelected.value(value.value))
  } else {
    return props.values.find((value) => isSelected.value(value.value))
  }
})
const selectedValueLabel = computed(() => {
  if (props.multiple) {
    return selectedValue.value.reduce((label, value) => {
      return label + (label ? ', ' : '') + value.label
    }, '')
  } else {
    return selectedValue.value.label
  }
})
const select = (value) => {
  if (props.multiple) {
    const result = props.modelValue ? props.modelValue.concat([]) : []
    const valueIndex = result.findIndex((resultValue) => resultValue === value)

    if (valueIndex === -1) {
      result.push(value)
    } else {
      result.splice(valueIndex, 1)
    }
    emit('update:modelValue', result)
  } else {
    if (isSearchInput.value) {
      emit('update:search', props.values.find((val) => val.value === value).label)
    }
    emit('update:modelValue', value)
    toogleSelect()
  }
}

watch(
  () => props.values,
  () => {
    if (isSearchInput.value) {
      dropdownOpen.value =
        props.values.length !== 0 && props.values[0].label !== props.search && props.search !== ''
    }
  },
)
</script>
