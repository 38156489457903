import { useFetch, useGet, useUpdate, useRemove, useCreate, useAxios } from '@/services/api'
import languages from '@/config/languagesArticles'

const articles = {
  namespaced: true,
  state: {
    all: [],
    translations: {},
    loading: false,
    filters: {
      title: '',
      status: 'all',
      translation: 'all',
      author: 'all',
      category: 'all',
    },
    sort: '-changed',
    length: null,
    currentPage: 0,
    limitPerPage: 20,
    totalItems: 0,
  },

  getters: {
    getById: (state) => (id, lang) => {
      if (lang && lang !== 'en') {
        return state.translations[lang].all.find((article) => article.id === id)
      }

      return state.all.find((article) => article.id === id)
    },
    getTotalPage: (state) => {
      return Math.ceil(state.totalItems / state.limitPerPage)
    },
    getLoadingStatus: (state) => {
      return Boolean(state.loading)
    },
  },

  mutations: {
    initStore(state) {
      state.all = []
      languages
        .filter((lang) => !['all', 'en'].includes(lang.key))
        .forEach((language) => {
          state.translations[language.key] = { all: [] }
        })
    },
    setCurrentPage(state, total) {
      state.currentPage = total
    },
    setTotalItems(state, total) {
      state.totalItems = Number(total)
    },
    setArticles(state, articles) {
      articles.forEach((article) => {
        if (article.langcode === 'en') {
          const index = state.all.findIndex((art) => art.id == article.id)

          if (index > -1) {
            state.all[index] = article
          } else {
            state.all.push(article)
          }
        } else {
          const index = state.translations[article.langcode].all.findIndex(
            (art) => art.id == article.id,
          )

          if (index > -1) {
            state.translations[article.langcode].all[index] = article
          } else {
            state.translations[article.langcode].all.push(article)
          }
        }
      })
    },
    setArticle(state, article) {
      state.all[state.all.findIndex((item) => item.id === article.id)] = article
    },
    updateArticle(state, article) {
      state.all[state.all.findIndex((item) => item.id === article.id)] = Object.assign(
        {},
        state.all[state.all.findIndex((item) => item.id === article.id)],
        article,
      )
    },
    resetArticles(state) {
      state.all = []
      languages
        .filter((lang) => !['all', 'en'].includes(lang.key))
        .forEach((language) => {
          state.translations[language.key] = { all: [] }
        })
    },
    setSort(state, sort) {
      state.sort = sort
    },
    setStatusLoading(state, loading) {
      state.loading = loading
    },
    setStatusFilter(state, status) {
      state.filters.status = status
    },
    setTranslationFilter(state, translation) {
      state.filters.translation = translation
    },
    setCategoryFilter(state, category) {
      state.filters.category = category
    },
    setAuthorFilter(state, author) {
      state.filters.author = author
    },
    setTitleFilter(state, title) {
      state.filters.title = title
    },
    resetFilters(state) {
      state.filters.status = 'all'
      state.filters.translation = 'all'
      state.filters.category = 'all'
      state.filters.author = 'all'
    },
  },

  actions: {
    async resetFilters({ commit, dispatch }) {
      commit('resetFilters')
      await dispatch('fetchArticles')
    },
    async setStatusFilter({ commit, dispatch }, status) {
      commit('setStatusFilter', status)
      await dispatch('fetchArticles')
    },
    async setSort({ commit, dispatch }, sort) {
      commit('setSort', sort)
      await dispatch('fetchArticles')
    },
    async setTranslationFilter({ commit, dispatch }, translation) {
      commit('setTranslationFilter', translation)
      await dispatch('fetchArticles')
    },
    async setCategoryFilter({ commit, dispatch }, category) {
      commit('setCategoryFilter', category)
      await dispatch('fetchArticles')
    },
    async setAuthorFilter({ commit, dispatch }, author) {
      commit('setAuthorFilter', author)
      await dispatch('fetchArticles')
    },
    async setTitleFilter({ commit, dispatch }, title) {
      commit('setTitleFilter', title)
      await dispatch('fetchArticles')
    },
    async fetchArticles({ commit, state }, page) {
      commit('setCurrentPage', page)
      const offset = (page - 1) * state.limitPerPage || 0
      commit('resetArticles')
      commit('setStatusLoading', true)

      const filter = {}

      if (state.filters.title) {
        filter.title = {
          value: state.filters.title,
          operator: 'CONTAINS',
        }
      }

      if (state.filters.status && state.filters.status !== 'all') {
        filter.field_statut = {
          value: state.filters.status,
          operator: '=',
        }
      }

      if (state.filters.translation && state.filters.translation !== 'all') {
        filter.langcode = {
          value: state.filters.translation,
          operator: '=',
        }
      }

      if (state.filters.category && state.filters.category !== 'all') {
        filter.field_categories = {
          value: state.filters.category,
          operator: '=',
          path: 'field_categories.id',
        }
      }

      if (state.filters.author && state.filters.author !== 'all') {
        filter.uid = {
          value: state.filters.author,
          operator: '=',
          path: 'uid.id',
        }
      }
      let sort = null

      if (state.sort) {
        sort = state.sort
      }

      try {
        const articlesResponse = await useFetch('article', {
          include: 'field_categories,uid',
          filter,
          sort,
          page: { offset: offset, limit: state.limitPerPage },
        })
        commit('setArticles', articlesResponse.data)
        commit('setTotalItems', articlesResponse.meta.count)
        setTimeout(() => {
          commit('setStatusLoading', false)
        }, 600)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async createArticle({ commit, state }, { attributes }) {
      commit('setStatusLoading', true)

      try {
        const categoryResponse = await useCreate('article', { ...attributes })
        commit('setStatusLoading', false)

        return categoryResponse.data
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async fetchArticle({ commit }, { id }) {
      commit('resetArticles')
      commit('setStatusLoading', true)

      try {
        // Load english version
        const promiseArticles = []
        for (const lang of languages.filter((language) => !['all'].includes(language.key))) {
          promiseArticles.push(
            await useGet(
              'article',
              id,
              {
                include: 'uid,field_categories,revision_uid',
              },
              lang.key,
            ),
          )
        }
        const articles = await Promise.all(promiseArticles.map((result) => result.data))

        commit('setArticles', articles)
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async updateArticle({ commit, state, dispatch }, article) {
      commit('setStatusLoading', true)

      try {
        await useUpdate('article', article.id, article, {
          include: 'uid,field_categories',
        })

        await dispatch('fetchArticle', { id: article.id })
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async removeArticle({ commit }, idArticle) {
      commit('resetArticles')
      commit('setStatusLoading', true)

      try {
        await useRemove('article', idArticle)
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async requestTranslation({ getters, dispatch }, { id, email, lang }) {
      const axios = useAxios()
      await axios.post('/api/request_translation', {
        to_email: email,
        path: process.env.VUE_APP_URL + `/articles/${lang}/${id}`,
        langcode: lang,
      })

      await dispatch('updateArticle', { id, langcode: lang, field_statut: 'draft' })
    },
    async saveSettings({ commit, dispatch }, { idArticle, categories, solutions, lang }) {
      commit('setStatusLoading', true)
      try {
        await useUpdate(
          'articles-categories',
          idArticle,
          categories.map((cat) => {
            return { id: cat.id }
          }),
          null,
          lang,
        )
        await useUpdate(
          'articles-solutions',
          idArticle,
          solutions.map((sol) => {
            return { id: sol }
          }),
          null,
          lang,
        )

        const articleResponse = await useGet(
          'article',
          idArticle,
          {
            include: 'uid,field_categories',
          },
          lang,
        )

        commit('updateArticle', articleResponse.data)

        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
  },
}

export default articles
