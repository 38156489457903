const languages = [
  {
    key: 'all',
    name: 'All languages',
  },
  {
    key: 'en',
    name: 'English',
    category: {
      name: {
        slideover: {
          placeholder: 'Your category name',
        },
      },
    },
  },
  {
    key: 'fr',
    name: 'French',
    category: {
      name: {
        slideover: {
          placeholder: 'Le nom de votre catégorie',
        },
      },
    },
  },
  {
    key: 'de',
    name: 'Deutsch',
    category: {
      name: {
        slideover: {
          placeholder: 'Der name ihrer kategorie',
        },
      },
    },
  },
  {
    key: 'es',
    name: 'Spanish',
    category: {
      name: {
        slideover: {
          placeholder: 'El nombre de tu categoria',
        },
      },
    },
  },
]

export default languages
