<template>
  <div class="w-full h-16">
    <div>
      <div class="bg-white flex items-center border-b border-gray-300">
        <input
          v-model="search"
          type="text"
          aria-label="Search an article"
          class="
            w-full
            py-4
            px-12
            text-smaller
            font-semibold
            text-gray-700
            leading-tight
            placeholder-gray-500
            focus:outline-none
          "
          placeholder="Search an article"
          @keyup.enter="goToSearch"
        />
        <div class="p-4 flex flex-column">
          <div class="border-r w-16">
            <a
              v-if="$route.name !== 'articles.index' || !search"
              class="
                text-darkgray
                rounded-full
                p-2
                hover:bg-gray-100
                focus:outline-none
                w-12
                h-12
                flex
                items-center
                justify-center
              "
              @click="goToSearch"
            >
              <icon-base
                class="w-6 h-6"
                fill="#000000"
                icon-name="magnifying"
                height="20"
                width="20"
              >
                <icon-magnify />
              </icon-base>
            </a>
            <a
              v-else
              class="
                text-darkgray
                rounded-full
                p-2
                hover:bg-gray-100
                focus:outline-none
                w-12
                h-12
                flex
                items-center
                justify-center
              "
              @click="deleteSearch"
            >
              <icon-base class="w-6 h-6" fill="#000000" icon-name="cross" height="20" width="20">
                <icon-cross />
              </icon-base>
            </a>
          </div>
          <search-bar-user-menu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBarUserMenu from '@/components/SearchBarUserMenu'

export default {
  components: { SearchBarUserMenu },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    title() {
      return this.$store.state.articles.filters.title
    },
  },
  watch: {
    title() {
      this.search = this.title
    },
  },
  methods: {
    goToSearch() {
      this.$store.dispatch('articles/setTitleFilter', this.search)
      this.$router.push({
        name: 'articles.index',
        query: { search: this.search, page: this.$router.query?.page || 1 },
      })
    },
    deleteSearch() {
      this.search = ''
      this.$router.push({ name: 'articles.index' })
    },
  },
}
</script>
