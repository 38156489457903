<template>
  <div class="w-1/2 mr-8">
    <div class="flex flex-row py-5 bg-white rounded-t-xl justify-between border-b border-gray-200">
      <div class="flex ml-6 items-center">
        <icon-base class="mr-2 w-6 h-6" fill="#C9510D" icon-name="top" height="23" width="23">
          <icon-top />
        </icon-base>
        <p class="text-orange font-semibold text-smaller">TOP 10 ARTICLES</p>
      </div>
    </div>
    <article-list
      class="flex flex-col bg-white m-auto rounded-b-xl overflow-y-auto"
      style="max-height: 13.7rem"
      :articles="articles"
      :translations="false"
    />
  </div>
</template>

<script>
import ArticleList from '@/components/ArticleList'

export default {
  components: {
    ArticleList,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
