<template>
  <div class="flex flex-row px-12 py-4 mt-10 justify-between">
    <p class="text-3xl font-bold mt-3">
      {{ title }}
    </p>
    <button
      v-if="isEditor || isAdmin"
      class="
        flex
        py-3
        px-4
        cursor-pointer
        bg-orange
        hover:bg-darkorange
        text-white text-sm
        tracking-wider
        font-bold
        rounded-xl
        items-center
      "
      @click="redirect"
    >
      <div class="mr-2 bg-darkorange rounded-xl h-6 w-6">
        <icon-base
          class="h-4 w-4 mt-1 m-auto"
          icon-name="plus"
          height="20"
          width="20"
          fill="#FFFFFF"
          ><icon-plus
        /></icon-base>
      </div>
      {{ buttonLabel }}
    </button>
  </div>
</template>

<script>
import mixinRoles from '@/mixins/mixin-roles'

export default {
  mixins: [mixinRoles],
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    buttonLabel() {
      return this.$route.name === 'categories.index' ? 'Create category' : 'Create an article'
    },
  },
  methods: {
    async redirect() {
      if (this.$route.name === 'categories.index') {
        this.$emitter.emit('emitCreateCategory')
      } else {
        await this.$router.push({ name: 'articles.new' })
      }
    },
  },
}
</script>
