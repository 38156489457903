<template>
  <base-modal title="Delete article" :open="open">
    <template #content>
      <div class="py-6 px-9">
        <p class="text-sm font-semibold text-gray-500">
          Are you sure you want to cancel? This article won't be saved and your changes deleted
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex">
        <button
          type="button"
          class="
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-transparent
            shadow-sm
            px-4
            py-2
            mr-4
            bg-orange
            text-base
            font-semibold
            text-white
            hover:bg-darkgray
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange
            sm:ml-3 sm:w-auto sm:text-sm
          "
          @click="confirmCancel"
        >
          Delete article
        </button>
        <button
          type="button"
          class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-semibold
            text-gray-700
            hover:bg-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
            sm:mt-0 sm:w-auto sm:text-sm
          "
          @click="emits('close-modal')"
        >
          Go back
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/modals/BaseModal'
import { useRouter } from 'vue-router'

const router = useRouter()

const emits = defineEmits(['close-modal'])

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const confirmCancel = () => {
  router.push({ name: 'articles.index' })
}
</script>
