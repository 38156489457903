<template>
  <router-link
    :to="{ name: 'articles.show', params: { id: id, lang: lang.key } }"
    class="flex flex-col border-b-2 border-transparent hover:border-darkgray text-orange"
    :class="{ currentLink: current }"
  >
    <p class="item-selected mt-1 ml-1 font-semibold text-smaller">Article</p>
    <p class="ml-1 mb-4 font-semibold text-av text-gray-500">
      {{ lang.name }}
    </p>
  </router-link>
</template>

<script>
import languages from '@/config/languagesArticles'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    current() {
      return this.$route.name === 'articles.show'
    },
    lang() {
      let keyLang = this.$route.params.lang || 'en'

      return languages.find((lang) => lang.key === keyLang)
    },
  },
}
</script>

<style scoped>
.currentLink {
  @apply text-black;
  @apply border-darkgray;
}
</style>
