<template>
  <router-link
    :to="{ name: 'articles.show.datasets', params: { id: id, lang } }"
    class="flex flex-col border-b-2 border-transparent hover:border-darkgray text-orange"
    :class="{ currentLink: current }"
  >
    <p class="item mt-1 ml-1 font-semibold text-sm">Datasets</p>
    <p class="ml-1 font-semibold text-av text-gray-500">
      {{ language.name }}
    </p>
  </router-link>
</template>

<script>
import languages from '@/config/languagesArticles'

export default {
  props: {
    lang: {
      type: String,
      default: 'en',
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    current() {
      return this.$route.name === 'articles.show.datasets'
    },
    language() {
      return languages.find((lang) => lang.key === this.lang)
    },
  },
}
</script>

<style scoped>
.currentLink {
  @apply text-black;
  @apply border-darkgray;
}
</style>
