<template>
  <div class="border-b border-gray-300 hover:bg-gray-300 py-3.5 px-5">
    <router-link
      :to="{ name: 'articles.show', params: { id: article.id, lang: 'en' } }"
      active-class="text-black"
      class="block font-bold text-md hover:underline"
    >
      {{ article.title }}
    </router-link>
    <div class="flex justify-between items-center font-semibold">
      <p class="text-av text-gray-500 py-1.5">
        Modified {{ dateFromNow(article.revision_timestamp) }} by
        {{ article.revision_uid['data'].display_name }}
      </p>
      <div class="flex mr-5 text-gray-500 text-sm mb-1.5 items-center">
        <div
          v-for="(translation, lang) in article.translations"
          :key="translation.id"
          class="flex items-center"
        >
          <span
            class="h-2.5 w-2.5 mr-1.5 rounded-full"
            :class="`status-${translation.status ? translation.status : 'to_translate'}`"
          />
          <p class="mr-3">
            {{ lang }}
          </p>
        </div>
      </div>
    </div>
    <p
      v-if="!isEmpty(article.field_categories['data'])"
      class="text-gray-500 text-av font-semibold"
    >
      <span v-for="category in article.field_categories['data']" :key="category.id" class="mr-4">{{
        category.name
      }}</span>
    </p>
    <router-link
      v-else
      :to="{
        name: 'articles.show.settings',
        params: { id: article.id, lang: 'en' },
      }"
      class="text-sm text-orange hover:underline font-semibold"
    >
      No categories. Click here to associate some.
    </router-link>
  </div>
</template>

<script>
import mixinDate from '@/mixins/mixin-dates'
import { isEmpty } from 'lodash'

export default {
  mixins: [mixinDate],
  props: {
    article: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
}
</script>
