<template>
  <div
    class="
      flex
      border-b border-gray-300
      items-center
      justify-between
      text-gray-500 text-av
      font-semibold
    "
  >
    <p>{{ count }} articles</p>
    <div class="flex mr-5 py-5 text-gray-500 text-av items-center m-auto">
      <span class="h-2.5 w-2.5 mr-1.5 rounded-full status-published" />
      <p class="mr-3">published</p>
      <span class="h-2.5 w-2.5 mr-1.5 rounded-full status-draft" />
      <p class="mr-3">draft</p>
      <span class="h-2.5 w-2.5 mr-1.5 rounded-full status-to_translate" />
      <p class="mr-3">to translate</p>
      <span class="h-2.5 w-2.5 mr-1.5 rounded-full status-to_validate" />
      <p class="mr-3">to validate</p>
      <span class="h-2.5 w-2.5 mr-1.5 rounded-full status-unpublished" />
      unpublished
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: null,
    },
  },
}
</script>
