<template>
  <div v-if="articles" class="divide-y overflow-y-auto" style="max-height: 22.8rem">
    <article-item-list
      v-for="(article, index) in articles"
      :key="index"
      :article="article"
      :translations="translations"
      :class="{
        'px-6 py-3 border-gray-200': index !== articles.length,
      }"
    />
  </div>
</template>

<script>
import ArticleItemList from '@/components/ArticleItemList'

export default {
  components: {
    ArticleItemList,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    translations: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
