<template>
  <base-modal title="Send a request to translate" :open="open">
    <template #content>
      <div class="p-6 flex flex-col">
        Email address
        <select-input
          v-model:modelValue="translator"
          v-model:search="mailSearch"
          :values="translators"
          @update:search="updateTranslators"
        />
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="
          mt-3
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-orange
          text-base
          font-semibold
          text-white
          hover:bg-darkgray
          sm:mt-0 sm:w-auto sm:text-sm
        "
        @click="findTranslator"
      >
        Send request
      </button>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          px-4
          py-2
          bg-white
          text-base
          font-semibold
          text-gray-600
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
          sm:ml-3 sm:w-auto sm:text-sm
        "
        @click="$emit('close-modal')"
      >
        Cancel
      </button>
    </template>
  </base-modal>
</template>

<script>
import SelectInput from '@/components/inputs/SelectInput'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import BaseModal from '@/components/modals/BaseModal'

export default {
  components: {
    BaseModal,
    SelectInput,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const store = useStore()

    let translator = ref(null)
    let mailSearch = ref('')
    let translators = ref([])
    let searchTimeout = ref(null)
    const updateTranslators = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
      }
      searchTimeout.value = setTimeout(async () => {
        const data = await store.dispatch('authors/searchAuthors', mailSearch.value)
        translators.value = data.map((author) => {
          return { value: author.id, label: author.mail }
        })
      }, 500)
    }

    const findTranslator = async () => {
      try {
        await store.dispatch('articles/requestTranslation', {
          id: props.id,
          email: mailSearch.value,
          lang: props.lang,
        })
        emit('close-modal')
        window.location.reload()
      } catch (e) {
        notify({
          type: 'error',
          duration: 8000,
          title: 'Error while processing the request',
          text: e.message(),
        })
      }
    }

    return {
      findTranslator,
      translator,
      updateTranslators,
      mailSearch,
      translators,
    }
  },
}
</script>
