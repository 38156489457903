import articles from '@/store/modules/articles'
import auth from '@/store/modules/auth'
import authors from '@/store/modules/author'
import categories from '@/store/modules/categories'
import solutions from '@/store/modules/solutions'
import medias from '@/store/modules/medias'
import datasets from '@/store/modules/datasets'
import images from '@/store/modules/images'
import { createStore } from 'vuex'
import navBar from '@/store/modules/navbar'

export default createStore({
  strict: true,
  modules: {
    navBar,
    auth,
    articles,
    solutions,
    medias,
    categories,
    authors,
    datasets,
    images,
  },
})
