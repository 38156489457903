<template>
  <router-link
    :to="route"
    class="text-white font-semibold py-2.5 px-4 rounded-md text-sm"
    :class="classes"
  >
    {{ title }}
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
  },
}
</script>
