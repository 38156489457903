<template>
  <p class="font-bold py-2">No {{ lowercase(language.name) }} translation</p>
  <button
    class="
      bg-orange
      hover:bg-darkgray
      text-white
      font-semibold
      py-2.5
      px-4
      rounded-md
      text-sm
      w-max
      mt-4
    "
    type="button"
    @click="openModal"
  >
    Request a translation
  </button>
  <find-translator
    :id="id"
    :lang="lang"
    :open="isModalOpened"
    @close-modal="isModalOpened = false"
  />
</template>

<script>
import { computed, ref } from 'vue'
import FindTranslator from '@/components/modals/FindTranslator'
import languages from '@/config/languagesArticles'
export default {
  components: { FindTranslator },
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    lang: {
      type: String,
      require: true,
      default: 'en',
    },
  },
  setup(props) {
    const isModalOpened = ref(false)
    const language = computed(() => languages.find((lang) => lang.key === props.lang))

    return {
      openModal: () => {
        isModalOpened.value = true
      },
      isModalOpened,
      language,
      lowercase: (e) => e.toLowerCase(),
    }
  },
}
</script>
