<template>
  <div class="py-4 px-3 max-w-36 mr-2 bg-white rounded-md">
    <p class="font-semibold text-sm">
      {{ category }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: String,
      default: '',
    },
  },
}
</script>
