<template>
  <g>
    <path
      d="M17.977,13.449c.187-2.2-.044-2.654-2.085-3.449C11.367,8.237,6.818,6.534,2.3,4.748a4.944,4.944,0,0,1-1.86-1.32A1.944,1.944,0,0,1,.4,1,2.1,2.1,0,0,1,2.743.083c1.412.433,2.8.948,4.179,1.476q6.021,2.306,12.024,4.66a9.508,9.508,0,0,1,2,1.042,2.126,2.126,0,0,1,.329,3.511c-1.016.978-2.191,1.79-3.3,2.676"
      transform="translate(1.947)"
      fill="#fff"
    />
    <path
      d="M0,11.559l.993-.712Q3.211,9.162,5.431,7.478C8.4,5.237,11.372,3.01,14.323.752c.313-.239.638-.524.976-.752.3.188.949.65,1.1.752,1.791,1.217,2.125,2.807.265,4.387s-2.992,2.5-4.832,3.924a9.858,9.858,0,0,1-5.754,2.485v.012Z"
      transform="translate(0 10.441)"
      fill="#fff"
    />
  </g>
</template>
