<template>
  <article-base-view>
    <div class="flex flex-col items-center">
      <div class="flex flex-col mt-12 w-11/12 self-center">
        <div class="flex justify-between items-center mb-4">
          <p class="font-bold text-2xl">Datasets</p>
          <button
            class="
              flex
              py-3
              px-4
              cursor-pointer
              bg-orange
              hover:bg-darkorange
              text-white text-sm
              tracking-wider
              font-bold
              rounded-xl
              items-center
            "
            @click="openMediaManager"
          >
            <div class="mr-2 bg-darkorange rounded-xl h-6 w-6">
              <icon-base
                class="h-4 w-4 mt-1 m-auto"
                icon-name="plus"
                height="20"
                width="20"
                fill="#FFFFFF"
                ><icon-plus
              /></icon-base>
            </div>
            Dataset
          </button>
        </div>
      </div>
      <div v-if="state.isLoading" class="mt-24">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div
        v-else-if="!state.isLoading && datasets.length"
        class="mt-12 w-11/12 self-center grid gap-6 mb-8 grid-cols-3 overflow-y-auto"
      >
        <div
          v-for="dataset in datasets"
          :key="dataset.id"
          class="rounded-xl bg-white px-6 py-4 flex justify-between"
        >
          <p>{{ dataset.name }}</p>
          <button class="ml-8" type="button" @click="deleteDataset(dataset.id)">
            <font-awesome-icon icon="trash" :style="{ color: 'grey' }" />
          </button>
        </div>
      </div>
      <div
        v-else-if="!state.isLoading && datasets.length === 0"
        class="mt-24 text-center text-gray-600"
      >
        No dataset.
      </div>
    </div>
  </article-base-view>
  <modal-form-dataset
    :id="id"
    :open="modals.dataset.open"
    :lang="lang"
    @close="modals.dataset.open = false"
  />
  <confirm-modal
    :open="modals.deletion.open"
    title="Delete dataset"
    message="Are you sure you want to delete this dataset? This action cannot be undone."
    type="danger"
    @apply="deleteCurrentDataset"
    @close="resetDeletion"
  />
</template>

<script>
import ArticleBaseView from '@/components/views/ArticleBaseView'
import ModalFormDataset from '@/components/modals/FormDataset'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ArticleDatasets',
  components: { ConfirmModal, ModalFormDataset, ArticleBaseView },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      state: {
        isLoading: true,
      },
      modals: {
        dataset: {
          open: false,
        },
        deletion: {
          open: false,
          id: null,
        },
      },
    }
  },
  computed: {
    article() {
      return this.$store.getters['articles/getById'](this.id)
    },
    lang() {
      return this.$route.params.lang
    },
    datasets() {
      return this.$store.getters['datasets/getAll']
    },
  },
  watch: {
    async lang() {
      await this.loadDataset()
    },
  },
  async mounted() {
    await this.loadDataset()
  },
  methods: {
    async loadDataset() {
      this.state.isLoading = true
      try {
        await this.$store.dispatch('articles/fetchArticle', { id: this.id, lang: this.lang })
        await this.$store.dispatch('datasets/fetchDatasets', {
          idArticle: this.id,
          lang: this.lang,
        })
      } catch (e) {
        throw e
      }
      setTimeout(() => {
        this.state.isLoading = false
      }, 800)
    },
    async deleteCurrentDataset() {
      const article = this.$store.getters['articles/getById'](this.id)

      await this.$store.dispatch('datasets/deleteDataset', {
        article,
        idDataset: this.modals.deletion.id,
        lang: this.lang,
      })

      this.resetDeletion()
    },
    openMediaManager() {
      this.modals.dataset.open = true
    },
    deleteDataset(idDataset) {
      this.modals.deletion.id = idDataset
      this.modals.deletion.open = true
    },
    resetDeletion() {
      this.modals.deletion.id = null
      this.modals.deletion.open = false
    },
  },
}
</script>
