<template>
  <div
    class="
      flex
      rounded-xl
      bg-white
      py-4
      mb-1
      font-semibold
      cursor-move
      items-center
      justify-between
    "
    @mouseover="displayMenuButton = true"
    @mouseleave="displayMenuButton = false"
  >
    <div class="flex items-center">
      <div
        class="flex w-6 h-6 ml-3 mr-2 items-center"
        :class="{
          'cursor-pointer': category.elements.length,
          'opacity-0': !category.elements.length,
        }"
      >
        <icon-base
          v-if="isChildrenDisplayed"
          class="w-5 h-5"
          icon-name="showchild"
          height="24"
          width="24"
          @click="toggleChildren"
        >
          <icon-show-child />
        </icon-base>
        <icon-base
          v-else
          class="w-5 h-5"
          icon-name="showchild"
          height="24"
          width="24"
          @click="toggleChildren"
        >
          <icon-hide-child />
        </icon-base>
      </div>
      <div class="w-8 h-8 mr-3">
        <img v-if="category.field_image_url" :src="category.field_image_url" alt="" />
      </div>
      <p class="px-2 hover:underline cursor-pointer" @click="emitSlide(category.id)">
        {{ category.name }}
      </p>
    </div>
    <div class="flex items-center">
      <Menu as="div" class="relative inline-block px-5">
        <div>
          <MenuButton class="rounded-full focus:outline-none">
            <div v-show="displayMenuButton" class="text-orange cursor-pointer">
              <icon-base
                class="h-6 w-6"
                icon-name="categorymenu"
                height="24"
                width="24"
                fill="#C9510D"
              >
                <icon-category-menu />
              </icon-base>
            </div>
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="
              absolute
              right-0
              w-44
              -mt-8
              mr-14
              origin-top-right
              bg-darkgray
              rounded
              divide-y divide-gray-100
              ring-1 ring-gray-400 ring-opacity-5
              focus:outline-none
            "
          >
            <div>
              <MenuItem v-slot="{ active }">
                <button
                  :class="[
                    active ? 'bg-gray-700 text-white' : 'text-white',
                    'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm border-b border-gray-600',
                  ]"
                  @click="emitSlide(category.id)"
                >
                  Edit
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button
                  :class="[
                    active ? 'bg-gray-700 text-white' : 'text-white',
                    'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm border-b border-gray-600',
                  ]"
                  @click="emitSlide(category.id, 'sub')"
                >
                  Add sub-category
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button
                  :class="[
                    active ? 'bg-gray-700 text-white' : 'text-white',
                    'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm',
                  ]"
                  @click="showCategoryArticles(category.id)"
                >
                  Show articles
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
  <category-nested-draggable
    v-show="isChildrenDisplayed"
    :categories="category.elements"
    class="ml-10"
  />
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
  components: { Menu, MenuButton, MenuItem, MenuItems },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChildrenDisplayed: true,
      displayMenuButton: false,
      category: this.element,
    }
  },
  methods: {
    async showCategoryArticles(categoryId) {
      this.displayMenuButton = false
      await this.$store.dispatch('categories/fetchCategory', { id: categoryId })
      const category = this.$store.getters['categories/getById'](categoryId)
      this.$emitter.emit('showArticles', { category: category })
    },
    async emitSlide(categoryId, dest) {
      this.displayMenuButton = false
      this.$emitter.emit('emitSlide', { categoryId: categoryId, destination: dest })
    },
    toggleChildren() {
      this.isChildrenDisplayed = !this.isChildrenDisplayed
    },
  },
}
</script>
