import { useFetch, useGet, useUpdate, useRemove, useCreate, useAxios } from '@/services/api'
import languages from '@/config/languagesArticles'

const datasets = {
  namespaced: true,
  state: {
    all: [],
  },

  getters: {
    getById: (state) => (id) => {
      return state.all.find((dataset) => dataset.id === id)
    },

    getAll: (state) => {
      return state.all
    },
  },

  mutations: {
    resetDatasets(state) {
      state.all = []
    },
    setDatasets(state, datasets) {
      state.all = datasets
    },
  },

  actions: {
    async deleteDataset({ commit, getters, dispatch }, { article, idDataset, lang }) {
      try {
        const axios = useAxios()

        const art = JSON.parse(JSON.stringify(article))

        let url = `/jsonapi/node/article/${article.id}`

        if (lang !== 'en') {
          url = `/${lang}${url}`
        }

        const response = await axios.patch(
          url,
          {
            data: {
              type: 'node--article',
              id: article.id,
              attributes: {},
              relationships: {
                field_dataset: {
                  data: [...getters.getAll.filter((item) => item.id !== idDataset)],
                },
              },
            },
          },
          {
            headers: {
              'content-type': 'application/vnd.api+json',
              accept: 'application/vnd.api+json',
            },
          },
        )
      } catch (e) {
        throw e
      }

      return dispatch('fetchDatasets', { idArticle: article.id, lang })
    },
    async fetchDatasets({ commit }, { idArticle, lang }) {
      commit('resetDatasets')
      try {
        const axios = useAxios()
        let url = `/jsonapi/node/article/${idArticle}/relationships/field_dataset?include=field_dataset`

        if (lang !== 'en') {
          url = `/${lang}${url}`
        }

        const datasets = await axios.get(url)

        const cleanDatasets = []

        for (const [key, value] of Object.entries(datasets.data.data)) {
          const more = datasets.data.included.find((d) => d.id === value.id)

          if (more) {
            cleanDatasets.push({
              ...value,
              ...more.attributes,
            })
          }
        }

        commit('setDatasets', cleanDatasets)
      } catch (e) {
        throw e
      }
    },
  },
}

export default datasets
