<template>
  <router-link
    :to="{ name: 'articles.show.revisions', params: { id: id, lang } }"
    class="flex flex-col border-b-2 border-transparent hover:border-darkgray text-orange"
    :class="{ currentLink: current }"
  >
    <p class="item mt-1 ml-1 font-semibold text-sm">Revisions</p>
    <p class="ml-1 font-semibold text-av text-gray-500">
      {{ revisions.length }}
    </p>
  </router-link>
</template>

<script>
import { useAxios } from '@/services/api'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      revisions: [],
    }
  },
  computed: {
    current() {
      return this.$route.name === 'articles.show.revisions'
    },
  },
  async mounted() {
    const axios = useAxios()
    this.revisions = (
      await axios.get(`/api/article/${this.id}/revisions?lang=${this.$route.params.lang}`)
    ).data
  },
}
</script>

<style scoped>
.currentLink {
  @apply text-black;
  @apply border-darkgray;
}
</style>
