<template>
  <article-base-view v-if="isReady" :right-panel="hasRightPanel">
    <!--        MARKDOWN EDITOR -->
    <div v-if="hasTraduction" class="flex flex-col px-11 py-4">
      <textarea
        ref="inputTitle"
        v-model="fields.title"
        type="text"
        class="
          container
          mx-auto
          focus:outline-none
          font-bold
          text-4xl
          my-4
          bg-transparent
          resize-none
          overflow-hidden
        "
        :class="{ 'px-24': !hasRightPanel }"
        placeholder="Title"
      />

      <textarea
        ref="inputResume"
        v-model="fields.introduction"
        class="container mx-auto focus:outline-none my-4 bg-transparent resize-none overflow-hidden"
        :class="{ 'px-24': !hasRightPanel }"
        placeholder="Resume"
      />

      <badoum :markdown="fields.markdown" :lang="lang" :class="{ 'px-24': !hasRightPanel }" />
    </div>
    <div v-else class="flex flex-col px-11 py-4">
      <request-translation-body :id="id" :lang="lang" />
    </div>

    <template #right-panel>
      <div id="preview" class="px-10 py-4">
        <h1 class="font-bold text-4xl my-4 overflow-hidden pb-4 my-4" style="min-height: 80px">
          {{ englishArticle.title }}
        </h1>
        <p>{{ englishArticle.field_introduction }}</p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="py-4 article-body" v-html="englishArticle?.body?.processed" />
      </div>
    </template>

    <template v-if="hasTraduction" #footer>
      <footer-action-article
        :article="article"
        :disabled="!isReady"
        @save="save"
        @reset="initForm"
      />
    </template>
  </article-base-view>
  <div v-else class="flex h-screen">
    <div class="m-auto text-center">
      <font-awesome-icon icon="spinner" spin />
      <h3 v-if="isSaving">Your article is being saved, please do not leave the page.</h3>
      <h3 v-else>Cooking your view...</h3>
    </div>
  </div>
</template>

<script>
import ArticleBaseView from '@/components/views/ArticleBaseView'
import RequestTranslationBody from '@/components/articles/RequestTranslationBody'
import Badoum from '@/components/badoum'
import { notify } from '@kyvg/vue3-notification'
import FooterActionArticle from '@/components/articles/FooterActionArticle'
import mixinParser from '@/mixins/mixin-parser'

export default {
  name: 'Article',
  components: { FooterActionArticle, RequestTranslationBody, ArticleBaseView, Badoum },
  mixins: [mixinParser],
  props: {
    id: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReady: false,
      isSaving: false,
      fields: {
        title: '',
        introduction: '',
        markdown: null,
      },
    }
  },
  computed: {
    article() {
      return this.$store.getters['articles/getById'](this.id, this.lang)
    },
    englishArticle() {
      return this.$store.getters['articles/getById'](this.id, 'en')
    },
    hasTraduction() {
      return this.article?.field_statut && this.article.field_statut !== 'to_translate'
    },
    hasRightPanel() {
      return this.lang !== 'en' && this.hasTraduction
    },
  },
  watch: {
    async lang() {
      this.isReady = false
      await this.initForm()
      this.isReady = true
    },
    'fields.title'() {
      if (this.$refs.inputTitle) {
        this.$refs.inputTitle.style.height = 'auto'
        this.$refs.inputTitle.style.height = `${this.$refs.inputTitle.scrollHeight}px`
      }
    },
    'fields.introduction'() {
      if (this.$refs.inputResume) {
        this.$refs.inputResume.style.height = 'auto'
        this.$refs.inputResume.style.height = `${this.$refs.inputResume.scrollHeight}px`
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('articles/fetchArticle', { id: this.id })
    await this.initForm()
    this.isReady = true
    const setFocus = () => {
      setTimeout(() => {
        if (this.$refs.inputTitle) {
          this.$refs.inputTitle.focus()
        } else {
          setFocus()
        }
      }, 200)
    }

    setFocus()
  },
  methods: {
    async initForm() {
      this.fields.title = this.article.title
      this.fields.introduction = this.article.field_introduction
      this.fields.markdown = await this.parseMarkdownMediaToAbsolute(
        this.article.field_body_markdown,
      )
      window.editor?.setMarkdown(this.fields.markdown)
      window.editor?.setSelection(0)
      document.getElementById('article-container-hfull')?.scrollTo(0, 0)
    },
    updateFieldTitle(title) {
      this.fields.title = title.target.innerText
    },
    updateFieldIntroduction(introduction) {
      this.fields.introduction = introduction.target.innerText
    },
    async save(status) {
      const html = await this.parseMarkdownAbsoluteToMedia(window.editor.getHTML())
      const markdown = await this.parseMarkdownAbsoluteToMedia(window.editor.getMarkdown())
      const article = {
        id: this.id,
        body: {
          value: html,
          format: 'no_filter_html',
        },
        title: this.fields.title,
        field_body_markdown: markdown,
        field_introduction: this.fields.introduction,
        field_statut: status,
        langcode: this.lang,
      }

      // Do this after getMarkdown because we destroy the component
      this.isReady = false
      this.isSaving = true

      await this.$store.dispatch('articles/updateArticle', article)

      await this.initForm()

      this.$notify({
        type: 'success',
        title: 'Article saved successfully',
        text: 'The article is saved in the database',
      })

      setTimeout(() => {
        this.isSaving = false
        this.isReady = true
      }, 2000)
    },
  },
}
</script>
