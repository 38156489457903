<template>
  <div class="text-center p-4">
    <h1 class="text-6xl mb-8 font-bold">404</h1>
    <span class="text-2xl text-gray-500">Page Not Found</span>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  components: {},

  setup() {},
}
</script>
