<template>
  <div class="text-center p-4">
    <span class="text-2xl text-gray-500">{{ message }}</span>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { useRoute, useRouter } from 'vue-router'
import Cookies from 'js-cookie'
import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'Login',

  components: {},

  setup() {
    const message = ref('Login Processing')

    // Check if logger
    const loggedIn = Cookies.get('token') ?? false

    // Check if params is here
    const route = useRoute()
    const code = route.query?.code

    if (!loggedIn && code) {
      const store = useStore()
      const router = useRouter()

      store.dispatch('auth/login', code).then(
        () => {
          router.go({ name: 'dashboard' })
        },
        (error) => {
          message.value = 'Error during login validation, please try later...'
        },
      )
    }

    return {
      message,
    }
  },
}
</script>
