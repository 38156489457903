<template>
  <div v-if="article" class="flex flex-col h-full bg-lightgray items-center justify-between">
    <div id="article-container-hfull" class="w-full h-full overflow-auto relative">
      <div class="flex py-5 w-full bg-white justify-between">
        <div class="flex items-center">
          <router-link :to="{ name: 'articles.index' }" class="h-4 w-4 ml-4 m-auto cursor-pointer">
            <img src="@/assets/left-arrow.png" alt="" />
          </router-link>
          <p class="font-bold m-auto ml-4">
            {{ article.title }}
          </p>
        </div>
        <div class="flex items-center mr-8 w-64">
          <lang-switcher :id="article.id" />
        </div>
      </div>
      <div class="flex py-4 w-full bg-white border-t justify-between">
        <div class="flex ml-12 items-center text-av">
          <div class="border-r">
            <p class="mr-4 text-gray-600 font-semibold">
              <template v-if="!article.id"> Not created yet </template>
              <template v-else>
                Edited {{ dateFromNow(article.revision_timestamp) }} by
                {{ article.revision_uid['data'].display_name }}
              </template>
            </p>
          </div>
          <div>
            <div v-if="article.field_categories['data'].length > 0">
              <a
                v-for="category in article.field_categories['data']"
                :key="category.id"
                class="ml-4 text-gray-600 font-semibold text-sm"
                >{{ category.name }}</a
              >
            </div>
            <a v-else class="ml-4 text-gray-600 font-semibold text-sm">No categories</a>
          </div>
        </div>
        <div>
          <custom-button
            v-if="article.id"
            title="Article settings"
            :to="{ name: 'articles.show.settings', params: { id: article.id, lang } }"
            classes="bg-orange hover:bg-darkgray mr-8"
          />
          <p v-else class="px-4 text-sm font-semibold font-darkgray">
            This article isn't created yet and doesn't have settings.
          </p>
        </div>
      </div>

      <div class="flex mb-4">
        <div
          :class="{
            'w-1/2': rightPanelOpen,
            'w-5/6': rightPanel && !rightPanelOpen,
            'w-full': !rightPanel,
            'mr-11': !rightPanel && $route.name !== 'articles.show.settings',
          }"
        >
          <div v-if="preview" class="flex mt-8 ml-11 h-16 border-b border-gray-300 align-start">
            <article-preview :id="id" class="pr-24" />
            <article-dataset :id="id" :lang="lang" class="pr-24" />
            <revisions-preview :id="id" :lang="lang" class="pr-24" />
            <views-preview :id="id" :views="totalViews" class="pr-24" />
            <reacted-preview
              :id="id"
              :top="totalTop"
              :flop="totalFlop"
              :class="rightPanelOpen ? 'pr-8' : 'pr-24'"
            />
          </div>
          <slot />
        </div>

        <div
          v-if="rightPanel"
          class="flex flex-col bg-white mt-4"
          :class="{
            'w-1/2': rightPanelOpen,
            'w-1/6': !rightPanelOpen,
            'mr-3': rightPanelOpen,
          }"
        >
          <div
            class="border-b border-gray-300 flex pl-10 py-4 h-20 font-semibold text-orange"
            :class="rightPanelOpen ? 'mr-4' : 'mr-7'"
          >
            <div class="ml-auto flex min-w-max cursor-pointer" @click="toogleRightPanel">
              english version
              <div class="rounded-full bg-orange h-7 w-7 flex p-1 ml-2">
                <img
                  :src="require(`@/assets/visibility${!rightPanelOpen ? '_off' : ''}.svg`)"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="min-w-min">
              <slot name="right-panel" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'
import ArticlePreview from '@/components/articles/previews/ArticlePreview'
import ArticleDataset from '@/components/articles/previews/ArticleDataset'
import ReactedPreview from '@/components/articles/previews/ReactedPreview'
import ViewsPreview from '@/components/articles/previews/ViewsPreview'
import RevisionsPreview from '@/components/articles/previews/RevisionsPreview'
import LangSwitcher from '@/components/articles/LangSwitcher'
import mixinDate from '@/mixins/mixin-dates'

export default {
  name: 'ArticleBaseView',
  components: {
    CustomButton,
    ArticlePreview,
    ReactedPreview,
    ViewsPreview,
    RevisionsPreview,
    LangSwitcher,
    ArticleDataset,
  },
  mixins: [mixinDate],
  props: {
    preview: {
      type: Boolean,
      default: true,
    },
    rightPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReady: false,
      rightPanelOpen: true,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    lang() {
      return this.$route.params.lang
    },
    article() {
      return this.$store.getters['articles/getById'](this.id, this.lang)
    },
    totalViews() {
      return this.article.field_total_views || 0
    },
    totalTop() {
      return this.article.field_total_top || 0
    },
    totalFlop() {
      return this.article.field_total_flop || 0
    },
  },
  methods: {
    toogleRightPanel() {
      this.rightPanelOpen = !this.rightPanelOpen
    },
  },
}
</script>
