<template>
  <div class="flex align-center flex-row py-6">
    <div
      v-for="language in languageAvailable"
      :key="language.key"
      class="flex-1 item border-b-2 hover:border-darkgray cursor-pointer py-4"
      :class="{
        'border-darkgray': languageSelected === language.key,
      }"
      @click="languageSelected = language.key"
    >
      <p
        class="font-semibold text-sm"
        :class="{
          'text-darkgray': languageSelected === language.key,
        }"
      >
        {{ language.name }}
      </p>
    </div>
  </div>
  <div>
    <div v-for="language in languageAvailable" :key="language.key">
      <div v-show="languageSelected === language.key">
        <category-translation-language-form
          :category-id="categoryId"
          :lang="language.key"
          @update="updateFields"
        />
      </div>
    </div>
  </div>
</template>

<script>
import languages from '@/config/languagesArticles'
import CategoryTranslationLanguageForm from '@/components/Category/CategoryTranslationLanguageForm'
export default {
  name: 'CategoryTranslationForm',
  components: { CategoryTranslationLanguageForm },
  props: {
    categoryId: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update'],
  data() {
    return {
      languageSelected: 'fr',
      fields: {},
    }
  },
  computed: {
    languageAvailable() {
      return languages.filter((lang) => !['all', 'en'].includes(lang.key))
    },
  },
  watch: {
    fields: {
      deep: true,
      handler() {
        this.$emit('update', this.fields)
      },
    },
  },
  methods: {
    updateFields(fields) {
      this.fields[fields.lang] = fields
    },
  },
}
</script>

<style scoped>
.item {
  @apply text-orange;
  @apply pr-8;
}

.item-selected {
  @apply text-black;
  @apply border-b-2;
  @apply border-black;
}

.item-not-selected {
  @apply border-gray-300;
}
</style>
