import { useFetch } from '@/services/api'

const authors = {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },

  getters: {},

  mutations: {
    setAuthors(state, authors) {
      state.all = authors
    },
    resetAuthors(state) {
      state.all = []
    },
    setStatusLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async searchAuthors({ commit }, search) {
      commit('setStatusLoading', true)
      try {
        const usersResponse = await useFetch('user', {
          filter: { mail: { operator: 'CONTAINS', value: search } },
        })
        commit('setStatusLoading', false)

        return usersResponse.data
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async fetchAuthors({ commit }) {
      commit('resetAuthors')
      commit('setStatusLoading', true)

      try {
        const authorsResponse = await useFetch('user')
        commit('setAuthors', authorsResponse.data)
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
  },
}

export default authors
