export default {
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    isEditor() {
      return this.$store.getters['auth/isEditor']
    },
    isTranslator() {
      return this.$store.getters['auth/isTranslator']
    },
  },
}
