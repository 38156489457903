import { useAxios, useFetch, useCreate, postMedia } from '@/services/api'

const medias = {
  namespaced: true,
  state: {
    loading: false,
    length: null,
    currentPage: 0,
    limitPerPage: 20,
    totalItems: 0,
  },

  getters: {
    getTotalPage: (state) => {
      return Math.ceil(state.totalItems / state.limitPerPage)
    },
  },

  mutations: {
    setStatusLoading(state, loading) {
      state.loading = loading
    },
    setTotalItems(state, total) {
      state.totalItems = Number(total)
    },
  },

  actions: {
    async createDocument({}, { name, idMedia }) {
      const axios = useAxios()

      const document = await axios.post(
        '/jsonapi/media/document',
        {
          data: {
            type: 'media--document',
            attributes: {
              name: name,
            },
            relationships: {
              field_media_document: {
                data: {
                  type: 'file--file',
                  id: idMedia,
                  meta: {
                    display: true,
                    description: '',
                  },
                },
              },
            },
          },
        },
        {
          headers: {
            'content-type': 'application/vnd.api+json',
            accept: 'application/vnd.api+json',
          },
        },
      )

      return document.data.data
    },
    async postImage({}, { blob }) {
      const axios = useAxios()

      const image = await axios({
        method: 'post',
        url: '/jsonapi/media/image/field_media_image',
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'file; filename="' + blob.name + '"',
        },
        data: blob,
      })

      return image.data
    },
    async createImageMedia({}, { blob, image }) {
      const axios = useAxios()

      const media = await axios.post(
        '/jsonapi/media/image',
        {
          data: {
            type: 'media--image',
            attributes: {
              name: blob.name,
            },
            relationships: {
              field_media_image: {
                data: {
                  type: 'file--file',
                  id: image.id,
                  meta: {
                    alt: blob.name,
                    title: blob.name,
                  },
                },
              },
            },
          },
        },
        {
          headers: {
            'content-type': 'application/vnd.api+json',
            accept: 'application/vnd.api+json',
          },
        },
      )

      return media.data
    },
    async postMedia({ commit, dispatch }, { type, file, name }) {
      const media = await postMedia({
        type,
        file,
      })

      const body = {
        name: name || file.name,
      }

      body[`field_media_${type}`] = {
        type: 'file--file',
        id: media.data.id,
      }

      const mediaResponse = await useCreate(type, body, {
        include: `field_media_${type}`,
      })

      return mediaResponse.data
    },
    async fetchMedias({ commit, state }, { type, search, page }) {
      commit('setStatusLoading', true)

      const offset = (page - 1) * state.limitPerPage

      const params = {
        include: 'field_media_image',
      }

      if (search) {
        params.filter = { name: { operator: 'CONTAINS', value: search } }
      }

      params.page = { offset: offset, limit: state.limitPerPage }

      params.sort = '-changed'

      try {
        const medias = await useFetch(type, params)
        commit('setTotalItems', medias.meta.count)
        commit('setStatusLoading', false)

        return medias.data
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
  },
}

export default medias
