<template>
  <g
    id="recherche+profil"
    transform="translate(-1033.000000, -29.000000)"
    fill="#2B2E32"
    stroke="#2B2E32"
    stroke-width="0.2"
  >
    <g id="notifications" transform="translate(1034.000000, 29.000000)">
      <path
        id="Shape"
        d="M17.8133038,16.5460409 C17.5496797,15.8938391 17.4166961,15.2063586 17.4166961,14.5035075 L17.4166961,9.42859574 C17.4166961,5.90821459 15.0559086,2.95614915 11.8346423,1.96017871 C11.6431962,0.849694367 10.6727645,0 9.50002857,0 C8.32729266,0 7.35686097,0.849694367 7.16541488,1.96017871 C3.9441486,2.95614915 1.58336108,5.90821459 1.58336108,9.42859574 L1.58336108,14.5035075 C1.58336108,15.2063586 1.45037748,15.8938391 1.18675335,16.5460409 L0.0564213113,19.3512631 C-0.0410161789,19.5937332 -0.0108419884,19.8668877 0.136028598,20.0832674 C0.283699258,20.2996471 0.528750259,20.4285674 0.791631465,20.4285674 L6.80966803,20.4285674 C7.37097655,21.3754199 8.37215391,22 9.50002857,22 C10.6279032,22 11.6290806,21.3754199 12.1903891,20.4285674 L18.2083685,20.4285674 C18.4712497,20.4285674 18.7163007,20.2996471 18.8639714,20.0832674 C19.010842,19.8668877 19.0410162,19.5937332 18.9435787,19.3512631 C18.9436358,19.3512631 17.8133038,16.5460409 17.8133038,16.5460409 L17.8133038,16.5460409 Z M1.96059561,18.8571348 L2.65717365,17.1291601 C2.99503315,16.2912631 3.16664886,15.4073677 3.16664886,14.5034508 L3.16664886,9.42853902 C3.16664886,5.96186995 6.00782321,3.14280853 9.50002857,3.14280853 C12.9922339,3.14280853 15.8333511,5.96186995 15.8333511,9.42853902 L15.8333511,14.5034508 C15.8333511,15.407311 16.0049669,16.2912631 16.3428263,17.1291601 L17.0394044,18.8571348 L1.96059561,18.8571348 Z"
      ></path>
    </g>
  </g>
</template>
