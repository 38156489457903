<template>
  <page-title title="Articles" />
  <filter-menu-articles />

  <div class="flex flex-col px-12 h-full bg-lightgray m-auto mb-16">
    <article-list-header :count="countArticles" />
    <div v-if="isLoading" class="h-24 w-full text-center">
      <div class="my-24">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
    <template v-else>
      <article-card v-for="(article, index) in articles" :key="index" :article="article" />
      <paginator :current="currentPage" :total="countPage" @paginate-navigation="updatePage" />
    </template>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import ArticleCard from '@/components/ArticleCard'
import ArticleListHeader from '@/components/ArticleListHeader'
import FilterMenuArticles from '@/components/FilterMenuArticles'
import Paginator from '@/components/ui/Paginator'

export default {
  name: 'Articles',

  components: {
    Paginator,
    FilterMenuArticles,
    ArticleListHeader,
    ArticleCard,
    PageTitle,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('articles/setTitleFilter', '')
    next()
  },
  computed: {
    articles() {
      return this.$store.state.articles.all
    },
    countArticles() {
      return Number(this.$store.state.articles.totalItems)
    },
    countPage() {
      return this.$store.getters['articles/getTotalPage']
    },
    currentPage() {
      return Number(this.$route.query.page) || 1
    },
    isLoading() {
      return this.$store.getters['articles/getLoadingStatus']
    },
  },
  async mounted() {
    await this.loadArticles(this.$route.query.search, this.currentPage)
  },
  methods: {
    async loadArticles(search, page) {
      await this.$store.dispatch('articles/fetchArticles', page)
    },
    updatePage(paginator) {
      const query = {
        page: paginator.next,
      }

      if (this.$route.query.search) {
        query.search = this.$route.query.search
      }

      this.$router.push({
        name: 'articles.index',
        query,
      })
      this.loadArticles(null, paginator.next)
    },
  },
}
</script>
