<template>
  <div class="flex h-16 w-full border-t border-gray-300 items-center">
    <p class="w-1/2 font-semibold ml-4 text-smaller">
      {{ lang }}
    </p>
    <div class="flex w-1/2 items-center">
      <div class="flex items-center w-1/2">
        <span class="h-2.5 w-2.5 mr-2 rounded-full" :class="`status-${status}`" />
        <p class="text-av text-gray-500 font-semibold">
          {{ status.replace('_', ' ') }}
        </p>
      </div>
      <div v-if="status !== 'to translate'" class="flex items-center w-1/2">
        <div class="flex w-24 font-bold">
          <icon-base class="mr-1.5 w-6 h-6" height="23" width="23" fill="#6B7280">
            <icon-top />
          </icon-base>
          {{ top }}
        </div>
        <div class="flex w-24 font-bold">
          <icon-base class="mr-1.5 w-6 h-6" height="23" width="23" fill="#6B7280">
            <icon-flop />
          </icon-base>
          {{ flop }}
        </div>
      </div>
      <div v-else class="flex items-center w-1/2 opacity-30">
        <div class="flex w-24 font-bold">
          <icon-base class="mr-1.5 w-6 h-6" height="23" width="23" fill="#6B7280">
            <icon-top />
          </icon-base>
          0
        </div>
        <div class="flex w-24 font-bold">
          <icon-base class="mr-1.5 w-6 h-6" height="23" width="23" fill="#6B7280">
            <icon-flop />
          </icon-base>
          0
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    top: {
      type: Number,
      default: 0,
    },
    flop: {
      type: Number,
      default: 0,
    },
  },
}
</script>
