<template>
  <div v-if="article" class="hover:bg-gray-200 px-6 py-3.5">
    <router-link
      :to="{ name: 'articles.show', params: { id: article.uuid, lang: 'en' } }"
      class="block font-bold hover:underline mb-1"
    >
      {{ article.label }}
    </router-link>
    <div class="flex justify-between">
      <p class="text-gray-500 text-av font-semibold">
        Modified {{ dateFromNow(article.changed) }} by
        {{ article.owner_name }}
      </p>

      <div v-if="translations && article.translations[0]" class="flex items-center">
        <div
          v-for="(translation, lang) in article.translations[0]"
          :key="translation.id"
          class="flex items-center text-gray-500 text-av"
        >
          <span
            class="h-2.5 w-2.5 mr-1.5 rounded-full"
            :class="`status-${translation.status ? translation.status : 'to_translate'}`"
          />
          <p class="mr-3 ml-1.5 font-semibold">
            {{ lang }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinDate from '@/mixins/mixin-dates'

export default {
  mixins: [mixinDate],
  props: {
    article: {
      type: Object,
      default: () => {},
      required: true,
    },
    translations: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
