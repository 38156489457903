const state = () => ({
  extended: true,
})

const mutations = {
  extendNavBar(state) {
    state.extended = true
  },

  reduceNavBar(state) {
    state.extended = false
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
