<template>
  <div class="border-b border-gray-300 hover:bg-gray-300 py-3.5 px-5">
    <router-link
      :to="{ name: 'articles.show', params: { id: element.article_id, lang: 'en' } }"
      active-class="text-black"
      class="block font-bold text-md hover:underline"
    >
      {{ element.label }}
    </router-link>
    <div class="flex justify-between items-center font-semibold">
      <p class="text-av text-gray-500 mt-1.5">{{ element.changed_text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
}
</script>
