<template>
  <category-item-list
    v-for="category in categories"
    :id="category.id"
    :key="category.id"
    :selected-ids="selectedIds"
    :editable="editable"
    :generation="generation"
    :hide-children="hideChildren"
    :class="{
      'border-t': editable,
      'last:border-b': editable && generation === 0,
      'border-gray-300': editable,
    }"
    @toogle-category="$emit('toogle-category', $event)"
  />
</template>

<script>
import CategoryItemList from '@/components/CategoryItemList'

export default {
  components: { CategoryItemList },
  props: {
    categories: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideChildren: {
      type: Boolean,
      default: false,
    },
    generation: {
      type: Number,
      default: 0,
    },
  },
  emits: ['toogle-category'],
}
</script>
