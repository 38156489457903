<template>
  <div>
    <div
      class="flex py-4 items-center justify-between"
      :style="`margin-left: ${generation * 3}em`"
      :class="{
        'bg-white rounded-xl mt-1 cursor-move': !editable,
      }"
      @mouseover="displayMenuButton = true"
      @mouseleave="displayMenuButton = false"
    >
      <div class="flex items-center">
        <div
          class="flex w-6 h-6 ml-3 mr-2 items-center"
          :class="{ 'cursor-pointer': childrenCategories.length }"
        >
          <template v-if="childrenCategories.length && !hideChildren">
            <icon-base
              v-if="isChildrenDisplayed"
              class="w-5 h-5"
              icon-name="showchild"
              height="24"
              width="24"
              @click="childrenClosed = !childrenClosed"
            >
              <icon-show-child />
            </icon-base>
            <icon-base
              v-else
              class="w-5 h-5"
              icon-name="showchild"
              height="24"
              width="24"
              @click="childrenClosed = !childrenClosed"
            >
              <icon-hide-child />
            </icon-base>
          </template>
        </div>
        <icon-base
          v-if="!editable"
          class="w-8 h-8 mr-3"
          icon-name="headphones"
          height="512"
          width="512"
        >
          <icon-headphones />
        </icon-base>
        <div class="flex flex-col" :class="{ 'hover:underline cursor-pointer': !editable }">
          <p v-if="!editable" class="font-semibold" @click="emitSlide(category)">
            {{ category.name }}
          </p>
          <p v-else class="font-semibold">{{ category.name }}</p>
        </div>
      </div>
      <div class="flex">
        <div v-if="editable" class="ml-auto mr-4 flex">
          <input
            :checked="checked"
            type="checkbox"
            @click="$emit('toogle-category', category.id)"
          />
        </div>
        <Menu v-else as="div" class="relative inline-block px-5">
          <div>
            <MenuButton class="rounded-full focus:outline-none">
              <div v-show="displayMenuButton" class="text-orange cursor-pointer">
                <icon-base
                  class="h-6 w-6"
                  icon-name="categorymenu"
                  height="24"
                  width="24"
                  fill="#C9510D"
                >
                  <icon-category-menu />
                </icon-base>
              </div>
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="
                absolute
                right-0
                w-44
                -mt-8
                mr-14
                origin-top-right
                bg-darkgray
                rounded
                divide-y divide-gray-100
                ring-1 ring-gray-400 ring-opacity-5
                focus:outline-none
              "
            >
              <div>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active ? 'bg-gray-700 text-white' : 'text-white',
                      'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm border-b border-gray-600',
                    ]"
                    @click="emitSlide(category)"
                  >
                    Edit
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active ? 'bg-gray-700 text-white' : 'text-white',
                      'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm border-b border-gray-600',
                    ]"
                    @click="emitSlide(category, 'sub')"
                  >
                    Add sub-category
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[
                      active ? 'bg-gray-700 text-white' : 'text-white',
                      'group font-bold rounded flex items-center w-full px-4 py-2.5 text-sm',
                    ]"
                    @click="showCategoryArticles(category)"
                  >
                    Show articles
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <category-list
      v-if="isChildrenDisplayed"
      :categories="childrenCategories"
      :selected-ids="selectedIds"
      :generation="generation + 1"
      :hide-children="hideChildren"
      :editable="editable"
      @toogle-category="$emit('toogle-category', $event)"
    />
  </div>
</template>

<script setup>
import CategoryList from '@/components/CategoryList'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import useEmitter from '@/composables/useEmitter'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'

const emit = defineEmits(['toogle-category'])
const emitter = useEmitter()

const props = defineProps({
  id: {
    type: String,
    default: null,
    required: true,
  },
  selectedIds: {
    type: Array,
    default: () => [],
  },
  editable: {
    type: Boolean,
    default: false,
  },
  hideChildren: {
    type: Boolean,
    default: false,
  },
  generation: {
    type: Number,
    default: 0,
  },
})

const checked = computed(() => props.selectedIds.includes(props.id))

let childrenClosed = ref(true)
let displayMenuButton = ref(false)

const store = useStore()
const category = computed(() => store.getters['categories/getById'](props.id))
const childrenCategories = computed(() =>
  store.getters['categories/getChildrenCategories'](props.id),
)
const isChildrenDisplayed = computed(
  () => !childrenClosed.value && childrenCategories.value.length && !props.hideChildren,
)

const emitSlide = (category, dest) => {
  if (category) {
    emitter.emit('emitSlide', { category: category, destination: dest })
  } else {
    emitter.emit('emitSlide', { category: null })
  }
}

const hasSelectedChidlren = (parentId) => {
  const children = store.getters['categories/getChildrenCategories'](parentId)

  return children.reduce((show, child) => {
    return show || props.selectedIds.includes(child.id) || hasSelectedChidlren(child.id)
  }, false)
}

const showCategoryArticles = (category) => {
  emitter.emit('showArticles', { category: category })
}

onMounted(() => {
  if (props.selectedIds) {
    childrenClosed.value = !hasSelectedChidlren(props.id)
  }

  if (!props.editable) {
    childrenClosed.value = false
  }
})
</script>

<style scoped>
input[type='checkbox'] {
  -webkit-appearance: none;
  @apply bg-white;
  @apply border;
  @apply border-gray-300;
  position: relative;
  padding: 8px;
  border-radius: 3px;
}

input[type='checkbox']:checked {
  @apply border-orange;
}

input[type='checkbox']:checked:after {
  content: '\2713';
  font-size: 14px;
  position: absolute;
  top: -2px;
  left: 3px;
}

.category:hover .menu-button {
  @apply block;
}

.menu-item {
  @apply bg-darkgray;
  @apply py-2.5;
  @apply px-3;
  @apply border-b border-gray-700;
  @apply hover:bg-gray-700;
  @apply hover:underline;
  @apply cursor-pointer;
}
</style>
