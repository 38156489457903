<template>
  <div v-if="isProcessing" class="flex mt-10 justify-center">
    <font-awesome-icon icon="spinner" spin />
  </div>
  <draggable
    v-else
    :list="articles"
    ghost-class="ghost"
    @start="dragging = true"
    @change="updateSorting"
  >
    <template #item="{ element }">
      <div>
        <article-drag-item :element="element" />
      </div>
    </template>
  </draggable>
</template>

<script>
import { useAxios } from '@/services/api'
import { notify } from '@kyvg/vue3-notification'

import draggable from 'vuedraggable'
import ArticleDragItem from '@/components/dragdrop/ArticleDragItem'

export default {
  name: 'ArticlesDraggable',
  components: { ArticleDragItem, draggable },
  props: {
    articles: {
      type: Object,
      default: () => {},
      required: true,
    },
    categoryId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      isProcessing: null,
      dragging: false,
    }
  },
  computed: {
    axios() {
      return useAxios()
    },
  },
  methods: {
    async updateSorting() {
      this.isProcessing = true

      try {
        await this.axios.patch('/api/articles_categories_weight/' + this.categoryId, this.articles)
      } catch (e) {
        notify({
          type: 'error',
          duration: 6000,
          title: 'Error while updating structure',
          text: e,
        })
      }

      this.dragging = false
      this.isProcessing = false
    },
  },
}
</script>

<style scoped>
.ghost {
  @apply border-2;
  @apply border-solid;
  @apply border-orange;
  @apply opacity-50;
}
</style>
