<template>
  <article-base-view>
    <div class="flex flex-col items-center">
      <div class="flex flex-col py-10 w-11/12">
        <div class="flex w-full py-3 justify-between items-center">
          <p class="font-bold text-2xl">Reacted</p>
          <div class="flex justify-end items-center">
            <p class="font-light text-sm mr-3">Select range</p>
            <DatePicker
              :key="datePickerKey"
              v-model="range"
              color="orange"
              locale="en"
              :masks="{ input: 'MMMM DD, YYYY' }"
              :max-date="new Date()"
              is-range
              @update:modelValue="getViewEvolution"
            >
              <template #default="{ inputValue, inputEvents }">
                <button
                  class="border border-gray-300 px-4 py-2 rounded-md text-sm font-semibold"
                  v-on="inputEvents.start"
                >
                  {{ inputValue.start }} - {{ inputValue.end }}
                </button>
              </template>
            </DatePicker>

            <button
              class="ml-2 h-5 w-5 rounded-xl bg-gray-300 hover:bg-gray-500"
              type="reset"
              @click="resetDate"
            >
              <icon-base class="h-3 w-3 ml-1" width="20" height="20">
                <icon-cross />
              </icon-base>
            </button>
          </div>
        </div>
        <evolution-chart class="mt-6" :data="chartData">
          <template #header class="">
            <div
              v-for="(lang, langIndex) in languages"
              :key="lang.key"
              class="flex mr-3 items-center"
            >
              <span class="h-2.5 w-2.5 rounded-full" :class="`chart-bg-color-${langIndex}`" />
              <a class="text-av ml-2 font-semibold">{{ lang.name }}</a>
            </div>
          </template>
        </evolution-chart>
        <div class="flex flex-col py-10">
          <lang-reaction-count
            v-for="lang in languages"
            :key="lang.key"
            :lang="lang.name"
            :status="getStatus(lang.key)"
            :top="reactionEvolutionDetail[lang.key].map((a) => a.top).reduce((a, b) => a + b, 0)"
            :flop="reactionEvolutionDetail[lang.key].map((a) => a.flop).reduce((a, b) => a + b, 0)"
          />
        </div>
      </div>
    </div>
  </article-base-view>
</template>

<script>
import ArticleBaseView from '@/components/views/ArticleBaseView'
import LangReactionCount from '@/components/LangReactionCount'
import EvolutionChart from '@/components/charts/EvolutionChart'
import languages from '@/config/languagesArticles'
import { useAxios } from '@/services/api'

export default {
  name: 'ArticleReacted',
  components: { ArticleBaseView, LangReactionCount, EvolutionChart },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      range: {
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date(),
      },
      stats: {},
      state: {
        isLoading: true,
      },
      datePickerKey: 0,
    }
  },
  computed: {
    article() {
      return this.$store.getters['articles/getById'](this.id)
    },
    reactionEvolution() {
      const languages = {}
      for (const [date, entry] of Object.entries(this.stats)) {
        for (const [lang, value] of Object.entries(entry)) {
          if (!languages[lang]) {
            languages[lang] = []
          }

          languages[lang].push(value.top + value.flop)
        }
      }

      return languages
    },
    reactionEvolutionDetail() {
      const languages = {}
      for (const [date, entry] of Object.entries(this.stats)) {
        for (const [lang, value] of Object.entries(entry)) {
          if (!languages[lang]) {
            languages[lang] = []
          }

          languages[lang].push(value)
        }
      }

      return languages
    },
    languages() {
      return Object.keys(this.reactionEvolution).map((lang) => {
        return languages.find((l) => l.key === lang)
      })
    },
    labels() {
      return Object.keys(this.stats)
    },
    chartData() {
      const datasets = []
      for (const [lang, reactionEvolution] of Object.entries(this.reactionEvolution)) {
        datasets.push(reactionEvolution)
      }

      return {
        labels: this.labels,
        datasets: datasets,
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('articles/fetchArticle', { id: this.id })
    await this.getViewEvolution()
  },
  methods: {
    getStatus(lang) {
      return this.$store.getters['articles/getById'](this.id, lang)?.field_statut
    },
    async resetDate() {
      this.range.start = new Date(new Date().setDate(new Date().getDate() - 7))
      this.range.end = new Date()
      this.datePickerKey += 1
      await this.getViewEvolution()
    },
    async getViewEvolution() {
      this.state.isLoading = true

      const start = new Date(this.range.start)
      start.setUTCHours(0, 0, 0, 1)

      const end = new Date(this.range.end)
      end.setUTCHours(23, 59, 59, 999)

      const axios = useAxios()
      const stats = await axios.get(
        `/api/statistics-article/${this.id}?start=${start.toISOString()}&end=${end.toISOString()}`,
      )
      this.stats = stats.data.reacted

      this.state.isLoading = false
    },
  },
}
</script>
