<template>
  <delete-article :id="id" :open="modalOpen" @close-modal="modalOpen = false" />
  <article-settings-slide-over
    v-if="article"
    v-model:categories="articleCategories"
    :open="slideOverOpen"
    @close-slide-over="slideOverOpen = false"
  />

  <article-base-view :preview="false">
    <div class="flex mt-4 mb-2 py-5 w-full justify-between">
      <div class="flex">
        <router-link
          :to="{ name: 'articles.show', params: { id: article.id, lang } }"
          class="flex items-center"
        >
          <img alt="" class="h-4 w-4 ml-4 m-auto cursor-pointer" src="../assets/left-arrow.png" />
        </router-link>
        <p class="font-bold m-auto ml-4 text-xl">Article settings</p>
      </div>
    </div>
    <div class="w-full px-12">
      <div class="border-t border-b border-gray-400 py-6">
        <div class="flex justify-between w-full items-center">
          <p class="font-bold">Categories</p>
          <custom-button
            classes="bg-orange hover:bg-darkgray"
            title="Edit categories"
            @click="openSlide"
          />
        </div>
        <!-- Categories -->
        <div v-if="articleCategories.length" class="flex py-3 w-full">
          <article-category-card
            v-for="category in articleCategories"
            :key="`test${category.id}`"
            :category="category.name"
          />
        </div>
        <div v-else class="text-gray-600 text-sm font-semibold">
          There is no category associated to this article
        </div>
      </div>
    </div>
    <div class="w-full px-12">
      <div class="border-b border-gray-400 py-6">
        <div class="flex justify-between w-full items-center">
          <p class="font-bold">Solutions</p>
        </div>
        <!-- Solutions -->
        <select-input
          v-model="articleSolutions"
          class="py-6 min-w-1/4 max-w-max"
          :values="solutions"
          :multiple="true"
          placeholder="Select solutions"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex py-6 w-full bg-white border-t items-center justify-between">
        <div>
          <custom-button
            classes="bg-orange hover:bg-darkgray ml-12"
            title="Save"
            @click="saveSettings"
          />
          <custom-button
            v-if="article.field_statut === 'published'"
            classes="bg-gray-800 hover:bg-gray-500 ml-2"
            title="Unpublish"
            @click="unpublish"
          />
        </div>
        <div class="justify-end">
          <custom-button
            classes="bg-gray-400 hover:bg-darkgray mr-10"
            title="Delete article"
            @click="promptDelete"
          />
        </div>
      </div>
    </template>
  </article-base-view>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import ArticleBaseView from '@/components/views/ArticleBaseView'
import DeleteArticle from '@/components/modals/DeleteArticle'
import SelectInput from '@/components/inputs/SelectInput'
import ArticleCategoryCard from '@/components/ArticleCategoryCard'
import CustomButton from '@/components/CustomButton'
import ArticleSettingsSlideOver from '@/components/Slideover/ArticleSettingsSlideOver'

export default {
  name: 'ArticleSettings',
  components: {
    ArticleBaseView,
    DeleteArticle,
    SelectInput,
    ArticleCategoryCard,
    CustomButton,
    ArticleSettingsSlideOver,
  },
  data() {
    return {
      slideOverOpen: false,
      modalOpen: false,
      articleCategories: [],
      articleSolutions: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    lang() {
      return this.$route.params.lang
    },
    article() {
      return this.$store.getters['articles/getById'](this.id, this.lang)
    },
    solutions() {
      return this.$store.getters['solutions/getSolutions'].map((solution) => {
        return { label: solution.name, value: solution.id }
      })
    },
  },
  async mounted() {
    await this.$store.dispatch('articles/fetchArticle', { id: this.id })
    await this.$store.dispatch('solutions/fetchSolutions')

    this.articleCategories = this.article.field_categories['data'].concat([])
    this.articleSolutions = this.article.field_solutions['data'].map((sol) => sol.id)
  },
  methods: {
    openSlide() {
      this.slideOverOpen = true
    },
    promptDelete() {
      this.modalOpen = true
    },
    async unpublish() {
      await this.$store.dispatch('articles/updateArticle', {
        id: this.id,
        field_statut: 'unpublished',
      })

      notify({
        type: 'success',
        title: 'Article saved successfully',
        text: 'The article is saved in the database',
      })
    },
    async saveSettings() {
      await this.$store.dispatch('articles/saveSettings', {
        idArticle: this.id,
        categories: this.articleCategories,
        solutions: this.articleSolutions,
        lang: this.lang,
      })

      notify({
        type: 'success',
        title: 'Article settings saved successfully',
        text: 'Settings of the article is saved in the database',
      })
    },
  },
}
</script>
