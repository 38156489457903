<template>
  <base-slide-over
    :title="categoryName"
    :open="open"
    :is-larger="true"
    @close-slide-over="closeSlideOver"
  >
    <template #content>
      <div v-if="isProcessing" class="flex mt-10 justify-center">
        <font-awesome-icon icon="spinner" spin />
      </div>
      <div v-else class="bg-white py-4">
        <articles-draggable v-if="articles.length" :articles="articles" :category-id="categoryId" />
        <p v-else class="px-8 py-10 font-bold text-orange">
          Oops ! There are no articles associated to this category.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center">
        <custom-button
          :classes="'bg-orange hover:bg-darkgray'"
          title="Close"
          @click="closeSlideOver"
        />
      </div>
    </template>
  </base-slide-over>
</template>

<script>
import { useAxios } from '@/services/api'
import ArticlesDraggable from '@/components/dragdrop/ArticlesDraggable'
import BaseSlideOver from '@/components/Slideover/BaseSlideOver'
import CustomButton from '@/components/CustomButton'

export default {
  name: 'CategoryArticlesSlideOver',
  components: { CustomButton, BaseSlideOver, ArticlesDraggable },
  data() {
    return {
      open: false,
      articles: [],
      isProcessing: null,
      categoryName: '',
    }
  },
  computed: {
    axios() {
      return useAxios()
    },
    categoryId: {
      set: function (newValue) {
        this.$store.dispatch('articles/setCategoryFilter', newValue)
      },
      get: function () {
        return this.$store.state.articles.filters.category
      },
    },
  },
  async mounted() {
    this.$emitter.on('showArticles', (type) => this.toggleArticlesSlide(type.category))
  },
  unmounted() {
    this.categoryId = 'all'
  },
  methods: {
    async toggleArticlesSlide(c) {
      this.isProcessing = true
      this.open = true
      this.categoryId = c.id
      this.categoryName = c.name
      const result = await this.axios.get('/api/articles_categories_weight/' + this.categoryId)
      this.articles = result.data
      this.isProcessing = false
    },
    closeSlideOver() {
      this.open = false
    },
  },
}
</script>
