<template>
  <div>
    <div class="flex flex-row">
      <div class="w-2/5 h-screen bg-orange">
        <img src="../assets/logo_white.png" class="w-36 mt-2 mr-2 absolute" />
        <div class="flex flex-col h-full justify-between m-auto overflow-hidden">
          <p class="text-center w-3/4 m-auto text-4xl text-white font-bold mt-44">
            The leading data analysis and statistical solution for microsoft excel®
          </p>
          <img
            src="../assets/connexion-people.png"
            alt=""
            class="mix-blend-multiply opacity-60 -mb-16"
          />
        </div>
      </div>
      <div class="h-screen flex w-3/5 bg-gray-200">
        <div class="w-2/4 m-auto relative">
          <img
            src="../assets/connexion-ordinateur.png"
            alt=""
            class="absolute -left-20% -top-16% ml-30 z-0"
          />
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col z-10 relative">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
