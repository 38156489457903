<template>
  <div class="flex mt-2 h-16 w-full rounded-md bg-white items-center justify-between">
    <div class="flex items-center">
      <p class="font-bold ml-4">
        {{ date.toISOString().split('T')[0].replaceAll('-', '/') }}
      </p>
      <p class="px-2 font-semibold text-sm text-gray-500">
        |
        <a class="mx-2">{{
          date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }}</a>
        |
      </p>
      <p class="font-semibold text-sm text-gray-500">by {{ author }}</p>
    </div>
    <a v-if="index === 0 || actual" class="mr-6 text-av text-gray-500 font-semibold"
      >Actual revision</a
    >
    <custom-button
      v-else
      title="Restore"
      classes="bg-orange hover:bg-darkgray mr-3"
      @click="restore"
    />
  </div>
</template>

<script>
import CustomButton from './CustomButton'
import { useAxios } from '@/services/api'

export default {
  components: {
    CustomButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    date: {
      type: Date,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    restoreRoute: {
      type: String,
      default: '',
    },
    actual: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    vid: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    async restore() {
      const axios = useAxios()
      await axios.post(
        `/api/article/${this.id}/revisions/${this.vid}`,
        {},
        {
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      await this.$router.push({ name: 'articles.show', params: { id: this.id } })
    },
  },
}
</script>
