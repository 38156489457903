<template>
  <base-modal title="Add an image" @keydown.esc="$emit('close-modal')">
    <template #header="{ title }">
      <div class="flex items-center justify-between w-full">
        <div>{{ title }}</div>
        <div class="border flex rounded p-2" style="width: 21rem">
          <input
            v-model="search"
            type="text"
            :aria-label="`Search a ${type}`"
            class="w-full outline-none"
          />
          <icon-base class="w-6 h-6" fill="#000000" icon-name="magnifying" height="20" width="20">
            <icon-magnify />
          </icon-base>
        </div>
      </div>
    </template>

    <template #content>
      <div class="flex">
        <!--        SELECT IMAGE -->
        <div class="p-6" style="flex: 3">
          <h3 class="mb-6 text-gray-500">All images</h3>
          <div class="grid gap-6 mb-8 grid-cols-4 max-h-96 overflow-y-auto">
            <div
              v-for="file in values"
              :key="file.id"
              class="rounded border-2 cursor-pointer"
              :class="{ 'border-blue-500': isSelected(file.id) }"
              @click="selectFile(file)"
            >
              <img v-if="type === 'image'" :src="file.url" alt="" />
              <span v-else class="w-full flex justify-center">
                <font-awesome-icon icon="file" :style="{ color: 'grey' }" size="5x" class="p-1" />
              </span>
              <div class="flex p-2 overflow-hidden">
                <span v-if="type === 'image'" class="mr-2">
                  <font-awesome-icon :icon="type" :style="{ color: 'grey' }" />
                </span>
                <span>{{ file.name }}</span>
              </div>
            </div>
          </div>
          <div class="bg-lightgray w-full px-4 rounded">
            <paginator
              :current="currentPage"
              :total="countPage"
              @paginate-navigation="updatePage"
            />
          </div>
        </div>
        <!--        IMPORT IMAGE-->
        <div class="p-6 w-96">
          <h3 class="mb-6 text-gray-500">Import from my computer</h3>
          <div
            class="
              border-dashed border-2
              rounded
              flex-row
              text-center
              px-4
              py-12
              space-y-4
              max-h-96
              overflow-y-auto
            "
            :class="draggedClass"
            @dragleave.prevent="dragleave"
            @drop.prevent="drop"
            @dragover.prevent="dragover"
          >
            <template v-if="filesToAdd.length === 0">
              <font-awesome-icon icon="file-upload" size="6x" :style="{ color: 'grey' }" />
              <span class="block font-semibold">Drag and drop {{ type }}s here</span>
              <span class="block">or</span>
            </template>
            <div v-else>
              <div
                v-for="(file, fileIndex) in filesToAdd"
                :key="file.name"
                class="flex items-center cursor-pointer p-2"
              >
                <span class="overflow-hidden w-64 text-left">
                  {{ file.name }}
                </span>
                <icon-base
                  class="h-3 w-3 m-2 ml-auto rounded hover:bg-gray-200"
                  height="20"
                  width="20"
                  @click="deleteFile(fileIndex)"
                >
                  <icon-cross />
                </icon-base>
              </div>
            </div>

            <input
              id="fileInput"
              ref="fileInput"
              name="fileInput"
              type="file"
              multiple
              class="hidden"
              :accept="fileType"
              :disabled="isProcessing"
              @change="onNewFile($event.target.files)"
            />
            <label
              for="fileInput"
              class="
                block
                text-white
                font-semibold
                py-2.5
                px-4
                rounded-md
                text-sm
                bg-blue-500
                cursor-pointer
                focus:outline-none
              "
            >
              Browse {{ type }}s
            </label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex space-x-2 w-full">
        <button
          class="
            ml-auto
            text-white
            font-semibold
            py-2.5
            px-4
            rounded-md
            text-sm
            bg-green
            focus:outline-none
          "
          :disabled="isProcessing"
          @click="addFile"
        >
          <font-awesome-icon v-if="isProcessing" icon="spinner" spin />
          <template v-else>Add</template>
        </button>
        <button
          :disabled="isProcessing"
          class="font-semibold py-2.5 px-4 text-sm focus:outline-none"
          @click="$emit('close-modal')"
        >
          Cancel
        </button>
      </div>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '@/components/modals/BaseModal'
import Paginator from '@/components/ui/Paginator'
import notify from '@kyvg/vue3-notification'

export default {
  name: 'MediaManager',
  components: { BaseModal, Paginator },
  props: {
    type: {
      type: String,
      default: 'image',
    },
  },
  emits: ['files', 'close-modal'],
  data() {
    return {
      searchTimeout: null,
      search: '',
      isDragged: false,
      selectedFiles: [],
      filesToAdd: [],
      filesList: [],
      isProcessing: false,
      currentPage: 1,
    }
  },
  computed: {
    countPage() {
      return this.$store.getters['medias/getTotalPage']
    },
    draggedClass() {
      return this.isDragged ? 'bg-gray-100' : ''
    },
    fileType() {
      return this.type === 'image' ? '.jpg,.jpeg,.png,.svg,.gif' : ''
    },
    values() {
      return this.filesList.map((file) => {
        return this.cleanFileUrl(file)
      })
    },
  },
  watch: {
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(async () => {
        this.filesList = await this.$store.dispatch('medias/fetchMedias', {
          type: this.type,
          search: this.search,
          page: 1,
        })
      }, 500)
    },
  },
  async mounted() {
    this.filesList = await this.$store.dispatch('medias/fetchMedias', { type: this.type, page: 1 })
  },
  methods: {
    async updatePage(paginator) {
      const payload = {
        type: this.type,
        page: paginator.next,
      }

      if (this.search) {
        payload.search = this.search
      }

      this.currentPage = Number(paginator.next)
      this.filesList = await this.$store.dispatch('medias/fetchMedias', payload)
    },
    cleanFileUrl(file) {
      file.url = file.field_media_image.data.uri.url

      const pattern = /^((https?|ftps?):\/\/)/

      if (!pattern.test(file.url)) {
        // Fix double slash when no absolute url is given
        file.url = file.url.replace(/^\/+/, '')
        file.url = `${process.env.VUE_APP_CDN_MEDIA_URL}/${file.url}`
      }

      return file
    },
    async addFile() {
      this.isProcessing = true
      try {
        let newFile
        for (let i = 0; i < this.filesToAdd.length; i++) {
          newFile = await this.$store.dispatch('medias/postMedia', {
            type: this.type,
            file: this.filesToAdd[i],
          })
          newFile = this.cleanFileUrl(newFile)

          this.selectedFiles.push(newFile)
        }
      } catch (e) {
        notify({
          type: 'error',
          duration: 8000,
          title: 'Error while processing the request',
          text: e.message(),
        })
      }

      this.$emit('files', this.selectedFiles)
      this.$emit('close-modal')
      this.isProcessing = false
    },
    isSelected(fileId) {
      return this.selectedFiles.findIndex((file) => file.id === fileId) !== -1
    },
    selectFile(file) {
      const fileIndex = this.selectedFiles.findIndex((selectedFile) => file.id === selectedFile.id)

      if (fileIndex === -1) {
        this.selectedFiles.push(file)
      } else {
        this.selectedFiles.splice(fileIndex, 1)
      }
    },
    dragover() {
      this.isDragged = true
    },
    dragleave() {
      this.isDragged = false
    },
    drop(event) {
      this.isDragged = false

      this.onNewFile(
        [...event.dataTransfer.files].filter(
          (file) =>
            this.type !== 'image' || file.type === 'image/jpeg' || file.type === 'image/png',
        ),
      )
    },
    deleteFile(fileIndex) {
      this.filesToAdd.splice(fileIndex, 1)
    },
    onNewFile(files) {
      this.filesToAdd = [...this.filesToAdd, ...files]
    },
  },
}
</script>
