import { useGet } from '@/services/api'

const images = {
  namespaced: true,
  state: {
    all: [],
  },

  getters: {
    getAll: (state) => {
      return state.all
    },
    get: (state) => (id) => {
      return state.all.find((image) => image.id === id)
    },
  },

  mutations: {
    setImage(state, image) {
      const foundIndex = state.all.findIndex((x) => x.id === image.id)

      if (foundIndex !== -1) {
        state.all[foundIndex] = image
      } else {
        state.all.push(image)
      }
    },
  },

  actions: {
    async fetchImage({ commit, getters }, { id }) {
      let image = getters.get(id)

      if (image) {
        return image
      }

      image = await useGet('image', id)

      commit('setImage', image.data)

      return image.data
    },
    async fetchImageByUrl({ commit }, { url }) {
      const params = {}
      url = url.replace(/(https?|ftp):/, '')
      params.filter = {
        filter: {
          condition: {
            value: url,
            path: 'field_image_url',
            operator: 'CONTAINS',
          },
        },
      }
      params.page = {
        limit: 1,
      }

      const image = await useGet('image', null, params).then((data) => {
        const image = data?.data?.shift()

        return image
      })

      if (image) {
        commit('setImage', image)
      }

      return image
    },
  },
}

export default images
