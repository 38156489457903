<template>
  <base-modal title="Delete category" :open="open">
    <template #content>
      <div class="py-6 px-9">
        <p class="text-sm font-semibold text-gray-500">
          Are you sure you want to delete this category? This action cannot be undone.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex">
        <button
          type="button"
          class="
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-transparent
            shadow-sm
            px-4
            py-2
            mr-4
            bg-orange
            text-base
            font-semibold
            text-white
            hover:bg-darkgray
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange
            sm:ml-3 sm:w-auto sm:text-sm
          "
          @click="deleteCategory"
        >
          Delete category
        </button>
        <button
          type="button"
          class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-semibold
            text-gray-700
            hover:bg-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
            sm:mt-0 sm:w-auto sm:text-sm
          "
          @click="close"
        >
          Cancel
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import { notify } from '@kyvg/vue3-notification'

export default {
  name: 'DeleteCategoryModal',
  components: { BaseModal },
  emits: ['close-modal'],
  data() {
    return {
      open: false,
      id: null,
    }
  },
  mounted() {
    this.$emitter.on('modal-deletion-category', (params) => {
      this.open = params.open
      this.id = params?.id
    })
  },
  methods: {
    close() {
      this.open = false
      this.id = null
    },
    async deleteCategory() {
      try {
        await this.$store.dispatch('categories/removeCategory', this.id)

        notify({
          type: 'success',
          duration: 6000,
          title: 'Category deleted successfully',
          text: 'The category is no longer in the database',
        })
        await this.$emitter.emit('emitFetchCategories')
        this.close()
      } catch (e) {
        notify({
          type: 'error',
          duration: 8000,
          title: 'An error occurred while performing your request',
          text: e + '. Check network console for more details',
        })
      }
    },
  },
}
</script>
