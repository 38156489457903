<template>
  <router-link
    :to="{ name: 'articles.show.reacted', params: { id: id, lang } }"
    class="flex flex-col border-b-2 border-transparent hover:border-darkgray text-orange"
    :class="{ currentLink: current }"
  >
    <p class="item mt-1 ml-1 font-semibold text-smaller">Reacted</p>
    <div class="flex px-0.5 py-0.5 items-center">
      <icon-base class="h-4 w-4" height="23" width="23" fill="#6B7280">
        <icon-top />
      </icon-base>
      <p class="ml-1.5 font-semibold text-av text-gray-500">
        {{ top }}
      </p>

      <icon-base class="ml-3 h-4 w-4" height="23" width="23" fill="#6B7280">
        <icon-flop />
      </icon-base>
      <p class="ml-1.5 font-semibold text-sm text-gray-500">
        {{ flop }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 0,
    },
    flop: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    current() {
      return this.$route.name === 'articles.show.reacted'
    },
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>

<style scoped>
.currentLink {
  @apply text-black;
  @apply border-darkgray;
}
</style>
