<template>
  <base-modal :title="title" :open="open" :type="type">
    <template #content>
      <div class="py-6 px-9">
        <p class="text-sm font-semibold text-gray-500">{{ message }}</p>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row-reverse">
        <button
          type="button"
          class="
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-transparent
            shadow-sm
            px-4
            py-2
            bg-orange
            text-base
            font-semibold
            text-white
            hover:bg-darkgray
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange
            sm:ml-3 sm:w-auto sm:text-sm
          "
          @click="$emit('apply')"
        >
          Yes, I'm sure
        </button>
        <button
          type="button"
          class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-semibold
            text-gray-700
            hover:bg-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
            sm:mt-0 sm:w-auto sm:text-sm
          "
          @click="$emit('close')"
        >
          Cancel
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
  name: 'ConfirmModal',
  components: { BaseModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    message: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['apply', 'close'],
}
</script>
