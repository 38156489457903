<template>
  <base-slide-over
    :title="baseSlideTitle"
    :open="open"
    @close-slide-over="$emit('close-slide-over')"
  >
    <template #content>
      <category-form v-if="category" :category-id="category.id" @update="updateFields" />
      <category-form v-else @update="updateFields" />
    </template>
    <template #footer>
      <div class="flex items-center">
        <custom-button
          v-if="categoryId"
          :classes="'bg-orange hover:bg-darkgray'"
          title="Update"
          @click="save(category.id)"
        />
        <custom-button
          v-else
          :classes="'bg-orange hover:bg-darkgray'"
          title="Create"
          @click="save(null)"
        />
        <a
          class="text-gray-600 text-smaller font-semibold px-4 cursor-pointer"
          @click="$emit('close-slide-over')"
          >Cancel</a
        >
      </div>
      <div>
        <custom-button
          v-if="isRemovable"
          :classes="'bg-gray-400 hover:bg-gray-600'"
          title="Delete"
          @click="openDeletionModal"
        />
      </div>
    </template>
  </base-slide-over>
</template>

<script>
import CategoryForm from '@/components/Category/CategoryForm'
import CustomButton from '@/components/CustomButton'
import BaseSlideOver from '@/components/Slideover/BaseSlideOver'

export default {
  name: 'CategorySlideOver',
  components: { CategoryForm, CustomButton, BaseSlideOver },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: String,
      default: '',
      required: false,
    },
    parentId: {
      type: String,
      default: '',
      required: false,
    },
    isRemovable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['close-slide-over', 'fetch-categories'],
  data() {
    return {
      fields: {},
    }
  },
  computed: {
    category() {
      if (this.categoryId) {
        return this.$store.getters['categories/getById'](this.categoryId)
      }

      return {}
    },

    baseSlideTitle() {
      return this.categoryId ? `Edit ${this.category?.name} category` : 'New category'
    },
  },
  methods: {
    updateFields(fields) {
      this.fields = fields
    },
    async save(id) {
      const payload = {
        icon: '',
        attributes: {
          name: this.fields.name,
          description: {
            format: 'no_filter_html',
            value: this.fields.description,
          },
        },
      }

      if (this.parentId) {
        if (!payload.relationships) {
          payload.relationships = {}
        }
        payload.relationships['parent'] = {
          id: this.parentId,
          type: 'taxonomy_term--categories',
        }
      }

      // Check if media and upload it
      if (this.fields.file) {
        if (!payload.relationships) {
          payload.relationships = {}
        }
        const media = await this.$store.dispatch('medias/postMedia', {
          type: 'image',
          file: this.fields.file,
        })

        payload.relationships['field_image'] = {
          type: 'media--image',
          id: media.id,
        }
      }

      let response

      if (id) {
        payload.attributes.id = id
        response = await this.$store.dispatch('categories/updateCategory', payload)
      } else {
        response = await this.$store.dispatch('categories/createCategory', payload)
      }

      const category = response.data

      for (const [lang, fields] of Object.entries(this.fields.translations)) {
        const payload = {
          attributes: {
            id: category.id,
            name: fields.name,
            description: {
              format: 'no_filter_html',
              value: fields.description,
            },
            langcode: lang,
          },
        }
        await this.$store.dispatch('categories/updateCategory', payload)
      }
      this.$emit('close-slide-over')
      this.$emit('fetch-categories')
    },
    openDeletionModal() {
      this.$emit('close-slide-over')
      this.$emitter.emit('modal-deletion-category', { open: true, id: this.categoryId })
    },
  },
}
</script>
