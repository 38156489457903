<template>
  <draggable
    :list="categories"
    :group="{ name: 'group' }"
    item-key="id"
    ghost-class="ghost"
    @end="drag"
  >
    <template #item="{ element }">
      <div>
        <category-drag-item :element="element" />
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

import CategoryDragItem from '@/components/dragdrop/CategoryDragItem'

export default {
  name: 'CategoryNestedDraggable',
  components: { CategoryDragItem, draggable },
  props: {
    categories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    drag() {
      this.$emitter.emit('dragCategories')
    },
  },
}
</script>

<style scoped>
.ghost {
  @apply border-2;
  @apply border-solid;
  @apply border-orange;
  @apply rounded-xl;
  @apply opacity-50;
}
</style>
