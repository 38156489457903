<template>
  <div
    id="sidebar"
    ref="navbarExtended"
    class="flex flex-col w-72 bg-darkgray text-smaller"
    :class="{ hidden: !$store.state.navBar.extended }"
  >
    <div class="h-20 border-b border-gray-600">
      <router-link :to="{ name: 'dashboard' }">
        <img
          class="w-32 h-auto mt-5 ml-5 cursor-pointer"
          src="../assets/logo.svg"
          alt="Logo XLStat"
        />
      </router-link>
    </div>

    <router-link
      v-if="isAdmin"
      :to="{ name: 'dashboard' }"
      class="flex py-0.5 border-b border-gray-600 cursor-pointer items-center"
      :class="[isUrl('dashboard') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base class="w-10 h-10 ml-4 fill-current" icon-name="dashboard" height="36" width="36">
        <icon-dashboard />
      </icon-base>
      <span class="py-5 px-4 font-semibold">Dashboard</span>
    </router-link>

    <router-link
      v-if="isAdmin"
      to="/categories"
      class="flex h-16 border-b border-gray-600 cursor-pointer items-center"
      :class="[isUrl('categories') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base class="w-10 h-10 ml-4 fill-current" icon-name="categories" height="36" width="36">
        <icon-categories />
      </icon-base>
      <span class="block py-5 px-4 font-semibold">Categories</span>
    </router-link>

    <router-link
      :to="{ name: 'articles.index' }"
      class="flex h-16 border-b border-gray-600 cursor-pointer items-center"
      :class="[isUrl('articles') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base class="w-10 h-10 ml-4 fill-current" icon-name="articles" height="36" width="36">
        <icon-articles />
      </icon-base>
      <span class="block py-5 px-4 font-semibold">Articles</span>
    </router-link>

    <div class="flex h-16 cursor-pointer text-addinsoftGray" @click="reduceNav">
      <icon-base
        class="w-5 h-5 mt-3.5 ml-8 transform rotate-180 fill-current"
        icon-name="dashboard"
        height="13"
        width="18"
      >
        <icon-reduce />
      </icon-base>
      <a class="block mt-4 ml-5 text-xs cursor-pointer font-semibold">Reduce</a>
    </div>
  </div>
  <!-- Reduced navbar -->
  <div
    ref="navbarReduced"
    class="flex flex-col w-16 bg-darkgray"
    :class="{ hidden: $store.state.navBar.extended }"
  >
    <div class="h-20 border-b border-gray-600">
      <router-link :to="{ name: 'dashboard' }">
        <icon-base
          class="w-auto h-auto cursor-pointer w-10 h-10 mt-6 ml-4 fill-current"
          icon-name="dashboard"
          height="36"
          width="36"
        >
          <icon-small-logo />
        </icon-base>
      </router-link>
    </div>

    <router-link
      v-if="isAdmin"
      :to="{ name: 'dashboard' }"
      class="h-16 border-b border-gray-600 cursor-pointer"
      :class="[isUrl('dashboard') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base
        class="w-10 h-10 mt-3 m-auto fill-current"
        icon-name="dashboard"
        height="36"
        width="36"
      >
        <icon-dashboard />
      </icon-base>
    </router-link>

    <router-link
      v-if="isAdmin"
      to="/categories"
      class="h-16 border-b border-gray-600 cursor-pointer"
      :class="[isUrl('/categories*') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base
        class="w-10 h-10 mt-3 m-auto fill-current"
        icon-name="categories"
        height="36"
        width="36"
      >
        <icon-categories />
      </icon-base>
    </router-link>

    <router-link
      :to="{ name: 'articles.index' }"
      class="h-16 border-b border-gray-600 cursor-pointer"
      :class="[isUrl('/articles*') ? 'text-white' : 'text-addinsoftGray']"
    >
      <icon-base
        class="w-10 h-10 mt-3 m-auto fill-current"
        icon-name="articles"
        height="36"
        width="36"
      >
        <icon-articles />
      </icon-base>
    </router-link>

    <div class="h-16 cursor-pointer" @click="extendNav">
      <icon-base class="w-5 h-5 mt-3.5 m-auto" icon-name="extend" height="13" width="18">
        <icon-extend />
      </icon-base>
    </div>
  </div>
</template>

<script>
import IconSmallLogo from '@/components/icons/IconSmallLogo'
import mixinRoles from '@/mixins/mixin-roles'

export default {
  components: { IconSmallLogo },
  mixins: [mixinRoles],
  methods: {
    extendNav() {
      this.$store.commit('navBar/extendNavBar')
    },
    reduceNav() {
      this.$store.commit('navBar/reduceNavBar')
    },
    isUrl(...urls) {
      return urls.filter((url) => this.$route.path.match(url)).length
    },
  },
}
</script>
