<template>
  <div class="flex gap-4 px-11 py-5 w-full bg-white border-t items-center">
    <button
      v-if="!article || article.field_statut !== 'published'"
      type="button"
      class="bg-orange hover:bg-darkgray text-white font-semibold py-2.5 px-4 rounded-md text-sm"
      :disabled="disabled"
      @click="$emit('save', 'draft')"
    >
      Save as draft
    </button>
    <button
      v-if="isTranslator"
      type="button"
      class="hover:bg-darkgray text-white font-semibold py-2.5 px-4 rounded-md text-sm w-max"
      :disabled="disabled"
      :class="[disabled ? 'bg-darkgray' : 'bg-orange']"
      :style="disabled ? 'cursor: not-allowed;' : ''"
      @click="$emit('save', 'to_validate')"
    >
      Save and submit for validation
    </button>
    <button
      v-if="isAdmin || isEditor"
      type="button"
      class="hover:bg-darkgray text-white font-semibold py-2.5 px-4 rounded-md text-sm w-max"
      :disabled="disabled"
      :class="[disabled ? 'bg-darkgray' : 'bg-green']"
      :style="disabled ? 'cursor: not-allowed;' : ''"
      @click="$emit('save', 'published')"
    >
      Save and publish
    </button>
    <button
      v-if="article"
      type="button"
      class="bg-lightgray text-darkgray font-semibold py-2.5 px-4 rounded-md text-sm"
      :disabled="disabled"
      @click="openUrl(article.url)"
    >
      Open in frontend
    </button>
    <button
      class="font-semibold text-gray-500 text-sm"
      type="button"
      :disabled="disabled"
      :style="disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'"
      @click="$emit('reset')"
    >
      Reset
    </button>
  </div>
</template>

<script>
import mixinRoles from '@/mixins/mixin-roles'

export default {
  name: 'FooterActionArticle',
  mixins: [mixinRoles],
  props: {
    article: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'reset'],
  methods: {
    openUrl(url) {
      // The API endpoint returns absolute url with the same sub-domain:
      // We want preview url with different sub-domain
      const previewUrl = url.replace(process.env.VUE_APP_API_URL, process.env.VUE_APP_PREVIEW_URL)

      window.open(previewUrl)
    },
  },
}
</script>
