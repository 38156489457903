<template>
  <base-modal title="Upload a dataset" :open="true">
    <template #content>
      <div class="py-6 px-9">
        <label class="mb-2 block">Name</label>
        <input
          v-model="fields.name"
          type="text"
          class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none mb-5"
        />
        <input type="file" @change="onUploadFile" />
      </div>
      <input type="file" class="hidden" />
    </template>
    <template #footer>
      <div class="flex flex-row-reverse">
        <base-button
          class="flex items-center"
          :disabled="!isValidForm || isProcessing"
          level="primary"
          @click="save"
        >
          <font-awesome-icon v-if="isProcessing" icon="spinner" spin class="mr-2" />
          Continue
        </base-button>

        <base-button @click="close">Cancel</base-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import { useAxios } from '@/services/api'
import BaseButton from '@/components/inputs/BaseButton'

export default {
  name: 'ModalFormDataset',
  components: { BaseButton, BaseModal },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  emits: ['close'],
  data() {
    return {
      fields: {
        name: '',
        file: null,
      },
      isProcessing: false,
    }
  },
  computed: {
    isValidForm() {
      return this.fields.name && this.fields.file
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onUploadFile(event) {
      this.fields.file = event.target.files[0]
    },
    async save() {
      this.isProcessing = true
      const document = await this.$store.dispatch('medias/postMedia', {
        type: 'document',
        file: this.fields.file,
        name: this.fields.name,
      })

      const axios = useAxios()

      let url = `/jsonapi/node/article/${this.id}/relationships/field_dataset`

      if (this.lang !== 'en') {
        url = `/${this.lang}${url}`
      }

      await axios.post(
        url,
        {
          data: [
            {
              type: 'media--document',
              id: document.id,
            },
          ],
        },
        {
          headers: {
            'content-type': 'application/vnd.api+json',
            accept: 'application/vnd.api+json',
          },
        },
      )

      this.isProcessing = false
      this.close()
      this.$store.dispatch('datasets/fetchDatasets', { idArticle: this.id, lang: this.lang })
    },
  },
}
</script>
