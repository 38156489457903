import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const dateFromNow = (date) => {
  return dayjs(date).fromNow()
}

export default {
  methods: {
    dateFromNow,
  },
}
