<template>
  <component :is="layout">
    <router-view />
  </component>
  <notifications class="notification" />
</template>

<script>
import Cookies from 'js-cookie'
import { useAxios } from '@/services/api'

export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + 'Layout'
    },
  },
  async mounted() {
    const token = Cookies.get('token') ?? null

    if (token) {
      const axios = useAxios()
      const user = await axios.get('/api/user/me')
      this.$store.dispatch('auth/setUser', user.data)
    }
  },
}
</script>
