<template>
  <div class="flex flex-col h-full bg-lightgray items-center justify-between">
    <div id="article-container-hfull" class="w-full h-full overflow-auto">
      <div class="flex py-5 w-full bg-white justify-between">
        <div class="flex items-center">
          <router-link :to="{ name: 'articles.index' }" class="h-4 w-4 ml-4 m-auto cursor-pointer">
            <img src="@/assets/left-arrow.png" alt="" />
          </router-link>
          <p class="font-bold m-auto ml-4">New article</p>
        </div>
      </div>
      <div class="flex py-4 w-full bg-white border-t justify-between">
        <div class="flex ml-12 items-center text-av">
          <div class="border-r">
            <p class="mr-4 text-gray-600 font-semibold">Not created yet</p>
          </div>
          <div>
            <a class="ml-4 text-gray-600 font-semibold text-sm">No categories</a>
          </div>
        </div>
        <div>
          <p class="px-4 text-sm font-semibold font-darkgray">
            This article isn't created yet and doesn't have settings.
          </p>
        </div>
      </div>

      <div class="flex mb-4">
        <!-- MARKDOWN EDITOR -->
        <div class="flex flex-col px-11 py-4">
          <textarea
            ref="inputTitle"
            v-model="fields.title"
            type="text"
            class="
              container
              mx-auto
              focus:outline-none
              font-bold
              text-4xl
              my-4
              bg-transparent
              resize-none
              overflow-hidden
              px-24
            "
            placeholder="Title"
          />

          <textarea
            ref="inputResume"
            v-model="fields.introduction"
            class="
              container
              mx-auto
              focus:outline-none
              my-4
              bg-transparent
              resize-none
              overflow-hidden
              px-24
            "
            placeholder="Resume"
          />

          <badoum
            class="container mx-auto focus:outline-none my-4 px-24"
            :markdown="fields.markdown"
          />
        </div>
      </div>
    </div>
    <footer-action-article @save="create" @reset="modals.cancel.open = true" />
  </div>

  <cancel-new-article :open="modals.cancel.open" @close-modal="modals.cancel.open = false" />
</template>

<script>
import FooterActionArticle from '@/components/articles/FooterActionArticle'
import Badoum from '@/components/badoum'
import CancelNewArticle from '../components/modals/CancelNewArticle'
import { notify } from '@kyvg/vue3-notification'

export default {
  name: 'ArticleNew',
  components: { FooterActionArticle, Badoum, CancelNewArticle },
  data() {
    return {
      fields: {
        title: '',
        introduction: '',
        markdown: null,
      },
      modals: {
        cancel: {
          open: false,
        },
      },
    }
  },
  watch: {
    'fields.title'() {
      if (this.$refs.inputTitle) {
        this.$refs.inputTitle.style.height = 'auto'
        this.$refs.inputTitle.style.height = `${this.$refs.inputTitle.scrollHeight}px`
      }
    },
    'fields.introduction'() {
      if (this.$refs.inputResume) {
        this.$refs.inputResume.style.height = 'auto'
        this.$refs.inputResume.style.height = `${this.$refs.inputResume.scrollHeight}px`
      }
    },
  },
  mounted() {
    this.$refs.inputTitle.focus()
  },
  methods: {
    updateFieldTitle(title) {
      this.fields.title = title.target.innerText
    },
    updateFieldIntroduction(introduction) {
      this.fields.introduction = introduction.target.innerText
    },
    async create(status) {
      const payload = {
        attributes: {
          body: {
            value: window.editor.getHTML(),
            format: 'no_filter_html',
          },
          title: this.fields.title,
          field_body_markdown: window.editor.getMarkdown(),
          field_introduction: this.fields.introduction,
          field_statut: status,
        },
      }

      try {
        const article = await this.$store.dispatch('articles/createArticle', payload)
        notify({
          type: 'success',
          title: 'Article created and saved successfully',
          text: 'The article is saved in the database',
        })
        await this.$router.push({
          name: 'articles.show',
          params: { id: article.id, lang: 'en' },
        })
        window.location.reload()
      } catch (e) {
        notify({
          type: 'error',
          title: 'Error while processing request',
          text: e + '. Check network console for more details',
        })
      }
    },
  },
}
</script>
