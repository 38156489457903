<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="py-3 flex items-center justify-between border-t border-gray-200">
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Previous
      </a>
      <a
        href="#"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div></div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            type="button"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-l-md
              border border-gray-300
              text-sm
              font-medium
              hover:bg-gray-50
            "
            :class="{
              'text-gray-300': current <= 1,
              'text-gray-500': current > 1,
            }"
            :disabled="current <= 1"
            @click="previousPage"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <template v-for="n in pageShowed" :key="n">
            <span
              v-if="n > 1 && !pageShowed.includes(n - 1)"
              class="
                border-gray-300
                text-gray-500
                hover:bg-gray-50
                hidden
                md:inline-flex
                relative
                items-center
                px-4
                py-2
                border
                text-sm
                font-medium
              "
              >...</span
            >
            <button
              type="button"
              class="
                border-gray-300
                text-gray-500
                hover:bg-gray-50
                hidden
                md:inline-flex
                relative
                items-center
                px-4
                py-2
                border
                text-sm
                font-medium
              "
              :class="{
                'bg-white': n === current,
                'bg-lightgray': n !== current,
              }"
              @click="changePage(n)"
            >
              {{ n }}
            </button>
          </template>
          <button
            type="button"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-r-md
              border border-gray-300
              text-sm
              font-medium
              hover:bg-gray-50
            "
            :class="{
              'text-gray-300': current >= total,
              'text-gray-500': current < total,
            }"
            :disabled="current >= total"
            @click="nextPage"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Paginator',
  props: {
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    current: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  emits: ['paginate-navigation'],
  computed: {
    pageShowed() {
      return [
        ...new Set([
          1,
          this.current - 1,
          this.current,
          this.current + 1,
          Math.ceil(this.total / 2),
          this.total,
        ]),
      ]
        .filter((value) => value >= 1 && value <= this.total)
        .sort(function (a, b) {
          return a - b
        })
    },
  },
  methods: {
    previousPage() {
      this.changePage(this.current - 1)
    },
    nextPage() {
      this.changePage(this.current + 1)
    },
    changePage(next) {
      this.$emit('paginate-navigation', { current: this.current, next: next })
    },
  },
}
</script>
