import { useAxios } from '@/services/api'

export default {
  data() {
    return {
      mediaParsed: {},
    }
  },
  methods: {
    // case: pasting content in the editor with image pathes,
    // should be store.dispatched onload, otherwise they won't be resolved with their uuid at save time
    async fixUnresolveMediaUrls(markdown) {
      const medias = this.findByType(markdown, 'imageUrl')
      for (let url of medias) {
        url = url.replace(/\\/g, '')

        const image = await this.$store.dispatch('images/fetchImageByUrl', { url })

        if (!image) {
          this.$notify({
            duration: 5,
            type: 'info',
            title: `External image detected "${url}"`,
          })
        } else {
          markdown = markdown.replace(`${url}`, `media:${image.id}`)
        }
      }

      return markdown
    },
    async parseMarkdownMediaToAbsolute(markdown) {
      markdown = await this.fixUnresolveMediaUrls(markdown)

      const medias = this.findByType(markdown, 'media')

      for (let id of medias) {
        const image = await this.$store.dispatch('images/fetchImage', { id })

        if (image.field_image_url) {
          markdown = markdown.replace(`media:${id}`, `${image.field_image_url}`)
        }
      }

      return markdown
    },
    async parseMarkdownAbsoluteToMedia(content) {
      const images = await this.$store.getters['images/getAll']

      images.forEach((image) => {
        if (image.field_image_url) {
          let url = image.field_image_url
          let media = `media:${image.id}`

          content = content.replaceAll(url, media)
          content = content.replaceAll(url.replace(/_/g, '\\_'), media)
        }
      })

      return content
    },
    findByType(content, type) {
      let pattern
      switch (type) {
        case 'link':
        case 'image':
        case 'media':
          pattern = `/${type}:(?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/gim`
          break
        case 'imageUrl':
          // Only match images with url that has not been converted on previous save (due to copy/paste or not dispatched to $store)
          pattern = /!\[.*?\]\((?<uuid>https?\:\/\/.*?)\)/gim
          break
        default:
          return []
      }

      let m
      let regexp = new RegExp(eval(pattern))
      const items = []
      // Not sure about named group with RegExp exec (ok in Node)
      const regexGroup = { uuid: 1 }

      while (null !== (m = regexp.exec(content))) {
        items.push(m[regexGroup.uuid] || [])
      }

      return items
    },

    async parseHtml(html) {
      // Find links
      const links = this.findByType(html, 'link')
      for (const id of links) {
        const axios = useAxios()
        const link = await axios.get('/jsonapi/mink_link/mink_link/' + id)
        html = html.replace(
          `link:${id}`,
          `${link.data.data.attributes.field_url}" data-link-uuid="${id}`,
        )
      }

      // Find images
      const images = this.findByType(html, 'image')
      for (const id of images) {
        html = html.replace(`image:${id}`, `" data-media-id="${id}`)
      }

      return html
    },
  },
}
