import { useFetch, useCreate, useUpdate, useRemove, useGet, postMedia } from '@/services/api'
import languages from '@/config/languagesArticles'

const categories = {
  namespaced: true,
  state: {
    all: [],
    translations: {},
    currentForm: {
      name: '',
      description: '',
      url: '',
      icon: null,
    },
    loading: false,
    limitPerPage: 50,
    totalItems: 0,
  },

  getters: {
    getById: (state) => (id, lang) => {
      if (lang && lang !== 'en') {
        return state.translations[lang].all.find((category) => category.id === id)
      }

      return state.all.find((category) => category.id === id)
    },
    getRootCategories: (state) => {
      return state.all.filter((category) =>
        category.parent.data.map((parent) => parent.id).includes('virtual'),
      )
    },
    getChildrenCategories: (state) => (id) => {
      return state.all.filter((category) =>
        category.parent.data.map((parent) => parent.id).includes(id),
      )
    },
  },

  mutations: {
    initStore(state) {
      state.all = []
      languages
        .filter((lang) => !['all', 'en'].includes(lang.key))
        .forEach((language) => {
          state.translations[language.key] = { all: [] }
        })
    },
    setTotalItems(state, total) {
      state.totalItems = Number(total)
    },

    setCategories(state, categories) {
      state.all = categories
    },
    setCategoryTranslations(state, categories) {
      categories.forEach((category) => {
        if (category.langcode === 'en') {
          state.all.push(category)
        } else {
          state.translations[category.langcode].all.push(category)
        }
      })
    },
    setCategoryFormValues(state, category) {
      state.currentForm.name = category.name
      state.currentForm.description = category.description
      state.currentForm.url = category.url
      state.currentForm.icon = category.icon
    },
    resetCategoryFormValues(state) {
      state.currentForm.name = ''
      state.currentForm.description = ''
      state.currentForm.url = ''
      state.currentForm.icon = null
    },
    resetCategories(state) {
      state.all = []
    },
    resetCategoryTranslations(state) {
      languages
        .filter((lang) => !['all', 'en'].includes(lang.key))
        .forEach((language) => {
          state.translations[language.key] = { all: [] }
        })
    },
    setStatusLoading(state, loading) {
      state.loading = loading
    },
    updateCategory(state, category) {
      state.all[state.all.findIndex((item) => item.id === category.id)] = Object.assign(
        {},
        state.all[state.all.findIndex((item) => item.id === category.id)],
        category,
      )
    },
  },

  actions: {
    setFormValues({ commit }, category) {
      commit('setCategoryFormValues', category)
    },
    async searchCategories({ commit }, search) {
      commit('setStatusLoading', true)
      try {
        const categoriesResponse = await useFetch('categories', {
          filter: { name: { operator: 'CONTAINS', value: search } },
        })
        commit('setStatusLoading', false)

        return categoriesResponse.data
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async fetchCategoryTranslations({ commit }, { id }) {
      await commit('resetCategoryTranslations')
      commit('setStatusLoading', true)

      try {
        for (const lang of languages.filter((language) => !['all', 'en'].includes(language.key))) {
          const category = await useGet('categories', id, {}, lang.key)
          commit('setCategoryTranslations', [category.data])
        }

        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async updateCategory({ commit, state }, { attributes, relationships }) {
      commit('setStatusLoading', true)

      try {
        const categoryResponse = await useUpdate(
          'categories',
          attributes.id,
          { ...attributes, ...relationships },
          {},
        )
        commit('setStatusLoading', false)
        commit('updateCategory', categoryResponse.data)

        return categoryResponse
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async removeCategory({ commit }, idCategory) {
      commit('setStatusLoading', true)
      try {
        await useRemove('categories', idCategory)
        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async createCategory(
      { commit, state, dispatch },
      { attributes, relationships, icon, langcode },
    ) {
      commit('setStatusLoading', true)

      if (icon) {
        const media = await postMedia({
          type: 'image',
          file: icon,
        })

        if (!relationships) {
          relationships = {}
        }
        relationships.field_image = {
          data: {
            type: 'file--file',
            id: media.data.id,
          },
        }
      }

      try {
        const categoryResponse = await useCreate('categories', {
          ...attributes,
          ...relationships,
          langcode,
        })
        commit('setStatusLoading', false)

        return categoryResponse
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async fetchCategory({ commit }, { id }) {
      commit('setStatusLoading', true)

      try {
        const categoryResponse = await useGet('categories', id, { include: 'field_image' })
        commit('setCategories', [categoryResponse.data])

        commit('setStatusLoading', false)
      } catch (e) {
        commit('setStatusLoading', false)
        console.error(e)
        throw e
      }
    },
    async fetchCategories({ commit, state }) {
      commit('resetCategories')
      commit('setStatusLoading', true)

      const fetchAll = async (page) => {
        const offset = (page - 1) * state.limitPerPage
        const params = {
          include: 'field_image',
          page: { offset: offset, limit: state.limitPerPage },
        }

        try {
          return await useFetch('categories', params)
        } catch (e) {
          throw e
        }
      }
      const allCategories = []
      const categoriesResponse = await fetchAll(1)
      allCategories.push(...categoriesResponse.data)

      // Check number page
      const nbPage = Math.ceil(categoriesResponse.meta.count / state.limitPerPage)
      for (let i = 2; i <= nbPage; i++) {
        const categories = await fetchAll(i)
        allCategories.push(...categories.data)
      }
      commit('setCategories', allCategories)
      commit('setTotalItems', categoriesResponse.meta.count)

      return allCategories
    },
  },
}

export default categories
