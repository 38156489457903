import { createRouter, createWebHistory } from 'vue-router'
import store from './../store'
import ArticleReacted from '@/views/ArticleReacted.vue'
import ArticleRevisions from '@/views/ArticleRevisions.vue'
import ArticleSettings from '@/views/ArticleSettings.vue'
import ArticleViews from '@/views/ArticleViews.vue'
import ArticleVue from '@/views/Article.vue'
import ArticleNew from '@/views/ArticleNew.vue'
import ArticlesComponent from '@/views/Articles.vue'
import DashboardComponent from '@/views/Dashboard.vue'
import CategoriesComponent from '@/views/Categories.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'
import Cookies from 'js-cookie'
import ArticleDatasets from '@/views/ArticleDatasets'
import { useAxios } from '@/services/api'
import NotAuthorized from '@/views/NotAuthorized'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      layout: 'split',
    },
  },
  {
    path: '/',
    redirect: { name: 'dashboard' },
    meta: {
      requiresAuth: true,
      layout: 'default',
      search: true,
      roles: ['administrator', 'editor'],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardComponent,
    meta: {
      requiresAuth: true,
      layout: 'default',
      search: true,
      roles: ['administrator', 'editor'],
    },
  },
  {
    path: '/articles',
    name: 'articles.index',
    component: ArticlesComponent,
    meta: {
      requiresAuth: true,
      layout: 'default',
      search: true,
      roles: ['administrator', 'editor', 'translator'],
    },
  },
  {
    path: '/articles/:lang/:id/',
    name: 'articles.show',
    component: ArticleVue,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor', 'translator'],
    },
    props: true,
  },

  {
    path: '/articles/new',
    name: 'articles.new',
    component: ArticleNew,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor'],
    },
  },
  {
    path: '/articles/:lang/:id/settings/',
    name: 'articles.show.settings',
    component: ArticleSettings,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor'],
    },
  },
  {
    path: '/articles/:lang/:id/views/',
    name: 'articles.show.views',
    component: ArticleViews,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor', 'translator'],
    },
    props: true,
  },
  {
    path: '/articles/:lang/:id/reacted/',
    name: 'articles.show.reacted',
    component: ArticleReacted,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor'],
    },
    props: true,
  },
  {
    path: '/articles/:lang/:id/datasets/',
    name: 'articles.show.datasets',
    component: ArticleDatasets,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor'],
    },
    props: true,
  },
  {
    path: '/articles/:lang/:id/revisions/',
    name: 'articles.show.revisions',
    component: ArticleRevisions,
    meta: {
      requiresAuth: true,
      layout: 'default',
      roles: ['administrator', 'editor'],
    },
    props: true,
  },
  {
    path: '/categories',
    name: 'categories.index',
    component: CategoriesComponent,
    meta: {
      requiresAuth: true,
      layout: 'default',
      search: true,
      roles: ['administrator', 'editor'],
    },
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
    meta: {
      layout: 'split',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: NotAuthorized,
    meta: {
      layout: 'split',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  // If page doesnt exist
  if (!to.name) {
    return next({ name: 'not-found' })
  }

  const loggedIn = Cookies.get('token') ?? false

  const redirect_url = `${process.env.VUE_APP_URL}/login`

  if (to.name === 'login' && loggedIn) {
    return next({ name: 'dashboard' })
  }

  // Check if the user role is authorized
  let rolesAuthorized = to.matched.map((record) => record.meta.roles)[0] || []

  if (loggedIn && rolesAuthorized) {
    let user = store.getters['auth/getMe']

    if (!user) {
      const axios = useAxios()
      user = await axios.get('/api/user/me')
      user = user.data
      await store.dispatch('auth/setUser', user)
    }

    if (rolesAuthorized.length) {
      let authorizedRoute = user.roles.filter((role) => rolesAuthorized.includes(role))

      if (authorizedRoute.length === 0) {
        if (
          !store.getters['auth/isAdmin'] &&
          store.getters['auth/isTranslator'] &&
          to.matched.some((record) => ['/', '/dashboard'].includes(record.path))
        ) {
          return next('/articles')
        }

        // redirect to 403
        return next('/not-authorized')
      }
    }
  }

  if (!loggedIn && to.matched.some((record) => record.meta.requiresAuth)) {
    return (window.location.href = `${process.env.VUE_APP_AUTH_URL}/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_API_CLIENT_ID}&redirect_uri=${redirect_url}`)
  }

  next()
})

export default router
