<template>
  <article-base-view>
    <div class="flex flex-col items-center">
      <div class="flex flex-col mt-12 w-11/12 self-center">
        <p class="font-bold text-2xl mb-5">Revisions</p>

        <div v-if="state.isLoading" class="mt-24 text-center">
          <font-awesome-icon icon="spinner" spin />
        </div>
        <div
          v-else-if="!state.isLoading && revisions.length === 0 && !state.error"
          class="mt-24 text-center text-gray-600"
        >
          No revision.
        </div>
        <div v-else-if="!state.isLoading && state.error" class="mt-24 text-center text-gray-600">
          {{ state.error }}
        </div>
        <div v-else-if="!state.isLoading && revisions.length">
          <revision-card
            v-for="(revision, index) in revisions"
            :id="id"
            :key="revision.uuid"
            :index="index"
            :author="revision.owner_name"
            :date="new Date(revision.revision_date)"
            :actual="revision.is_actual"
            :vid="revision.vid"
          />
        </div>
      </div>
    </div>
  </article-base-view>
</template>

<script>
import ArticleBaseView from '@/components/views/ArticleBaseView'
import RevisionCard from '@/components/RevisionCard'
import { useAxios } from '@/services/api'

export default {
  name: 'ArticleRevisions',
  components: { ArticleBaseView, RevisionCard },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      revisions: [],
      state: {
        isLoading: true,
        isLoaded: false,
        error: null,
      },
    }
  },
  computed: {
    article() {
      return this.$store.getters['articles/getById'](this.id)
    },
    lang() {
      return this.$route.params.lang
    },
  },
  watch: {
    async lang() {
      await this.loadRevisions()
    },
  },
  async mounted() {
    await this.loadRevisions()
  },
  methods: {
    async loadRevisions() {
      this.state.isLoading = true
      this.state.error = null
      this.state.isLoaded = false

      try {
        await this.$store.dispatch('articles/fetchArticle', { id: this.id })
        const axios = useAxios()
        this.revisions = (
          await axios.get(`/api/article/${this.id}/revisions?lang=${this.lang}`)
        ).data
      } catch (e) {
        this.state.error = e.toLocaleString()
      }

      setTimeout(() => {
        this.state.isLoading = false
      }, 600)
    },
  },
}
</script>
