<template>
  <div class="flex flex-col">
    <p>Category name</p>
    <input
      v-model="fields.name"
      type="text"
      class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
    />
  </div>
  <div v-if="category?.path?.alias" class="flex flex-col mt-5">
    <p>Url</p>
    <input
      v-model="category.path.alias"
      type="text"
      disabled
      class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
    />
  </div>
  <div class="flex flex-col mt-5 mb-5">
    <p>Category description</p>
    <textarea
      v-model="fields.description"
      class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
    />
  </div>
</template>

<script>
import mixinSlugify from '@/mixins/mixin-slugify'

export default {
  name: 'CategoryTranslationLanguageForm',
  mixins: [mixinSlugify],
  props: {
    lang: {
      type: String,
      default: 'en',
      required: true,
    },
    categoryId: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update'],
  data() {
    return {
      fields: {
        name: '',
        description: '',
        lang: this.lang,
        pid: null,
      },
    }
  },
  computed: {
    category() {
      if (this.categoryId) {
        return this.$store.getters['categories/getById'](this.categoryId, this.lang)
      }

      return {}
    },
  },
  watch: {
    fields: {
      deep: true,
      handler() {
        this.$emit('update', this.fields)
      },
    },
  },
  mounted() {
    if (this.category) {
      this.fields.name = this.category?.name || ''
      this.fields.description = this.category?.description?.value || ''
      this.fields.pid = this.category?.path?.pid || null
    }
  },
}
</script>
