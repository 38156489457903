<template>
  <g transform="translate(-21.000000, -284.000000)" stroke="#6C757D" stroke-width="2">
    <g transform="translate(22.000000, 285.000000)">
      <g
        transform="translate(3.000000, 5.418501) scale(-1, 1) rotate(90.000000) translate(-3.000000, -5.418501) translate(-2.418501, 2.418501)"
      >
        <line x1="1.64271409e-16" y1="1.66533454e-16" x2="5.91850124" y2="6" />
        <line
          x1="4.91850124"
          y1="1.66533454e-16"
          x2="10.8370025"
          y2="6"
          transform="translate(7.877752, 3.000000) scale(-1, 1) translate(-7.877752, -3.000000) "
        />
      </g>
      <g
        transform="translate(13.000000, 5.418501) scale(-1, 1) rotate(90.000000) translate(-13.000000, -5.418501) translate(7.581499, 2.418501)"
      >
        <line x1="1.64271409e-16" y1="1.66533454e-16" x2="5.91850124" y2="6" />
        <line
          x1="4.91850124"
          y1="1.66533454e-16"
          x2="10.8370025"
          y2="6"
          transform="translate(7.877752, 3.000000) scale(-1, 1) translate(-7.877752, -3.000000) "
        />
      </g>
    </g>
  </g>
</template>
