<template>
  <base-modal title="Enter your latex formule" :open="true">
    <template #content>
      <div class="container mx-auto px-9 py-4">
        <label>Formula :</label>
        <textarea
          v-model="formula"
          class="resize-none w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          rows="10"
        ></textarea>
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="
          mt-3
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-orange
          text-base
          font-semibold
          text-white
          hover:bg-darkgray
          sm:mt-0 sm:w-auto sm:text-sm
        "
        @click="$emit('apply', { slug: 'math', payload: formula })"
      >
        Continue
      </button>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          px-4
          py-2
          bg-white
          text-base
          font-semibold
          text-gray-600
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
          sm:ml-3 sm:w-auto sm:text-sm
        "
        @click="$emit('close-modal')"
      >
        Cancel
      </button>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/modals/BaseModal'
import { ref } from 'vue'

const emits = defineEmits(['apply', 'close-modal'])

const formula = ref('')
</script>
