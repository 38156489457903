<template>
  <div class="absolute inset-0 px-4 sm:px-6">
    <div class="mt-6 flex flex-col h-full text-smaller" aria-hidden="true">
      <div class="flex flex-col">
        <p>Category name</p>
        <input
          v-model="fields.name"
          type="text"
          class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
          placeholder="Your category name"
        />
      </div>
      <div v-if="category?.path?.alias" class="flex flex-col mt-5">
        <p>Url</p>
        <input
          v-model="category.path.alias"
          type="text"
          disabled
          class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
          placeholder="xlstat-example/article"
        />
      </div>
      <div class="flex flex-col mt-5">
        <p>Category description</p>
        <textarea
          v-model="fields.description"
          class="mt-3 outline-none py-2 px-2 font-semibold border border-gray-300 rounded"
        />
      </div>
      <div class="flex flex-col mt-5">
        <p>Category image (svg, png)</p>
        <label class="flex w-full h-full mt-3 cursor-pointer rounded" for="fileInput">
          <span
            class="
              flex
              w-full
              border-t border-b border-l border-gray-300
              px-2
              font-semibold
              outline-none
              cursor-pointer
              items-center
            "
          >
            {{ iconName }}
          </span>
          <span
            class="
              h-full
              border-t border-b border-r border-gray-300
              text-orange
              font-semibold
              px-2
              py-2
            "
          >
            Browse
          </span>
        </label>
        <input id="fileInput" type="file" hidden accept="image/*" @change="fileUpdated" />
      </div>
      <div
        v-if="iconContent"
        class="
          flex
          py-4
          px-4
          mt-5
          w-max
          max-w-1/2
          border border-gray-300
          rounded
          items-center
          relative
        "
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div
          v-if="fields.file && ['image/png', 'image/svg+xml'].includes(fields.file.type)"
          v-html="iconContent"
        />
        <img v-else :src="iconContent" />

        <icon-base
          class="
            w-6
            h-6
            p-1
            absolute
            top-0
            right-0
            translate-x-2/4
            -translate-y-2/4
            bg-lightgray
            rounded-full
            cursor-pointer
          "
          fill="#000000"
          icon-name="cross"
          height="20"
          width="20"
          @click="deleteFile"
        >
          <icon-cross />
        </icon-base>
      </div>

      <div class="border-b border-gray-300 mt-8" />

      <category-translation-form :category-id="categoryId" @update="updateTranslationFields" />
    </div>
  </div>
</template>

<script>
import CategoryTranslationForm from '@/components/Category/CategoryTranslationForm'
import mixinSlugify from '@/mixins/mixin-slugify'

export default {
  components: {
    CategoryTranslationForm,
  },
  mixins: [mixinSlugify],
  props: {
    categoryId: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update'],
  data() {
    return {
      fields: {
        name: '',
        description: '',
        file: '',
        translations: {},
      },
      previewSrc: '',
    }
  },
  computed: {
    category() {
      if (this.categoryId) {
        return this.$store.getters['categories/getById'](this.categoryId)
      }

      return {}
    },
    iconName() {
      return this.fields.file?.name || this.category?.field_image?.data?.name
    },
    iconContent() {
      return ''
    },
  },
  watch: {
    fields: {
      deep: true,
      handler() {
        this.$emit('update', this.fields)
      },
    },
  },
  mounted() {
    if (this.category) {
      this.fields.name = this.category?.name || ''
      this.fields.description = this.category?.description?.value || ''
    }
  },
  methods: {
    updateTranslationFields(fields) {
      this.fields.translations = fields
      this.$emit('update', this.fields)
    },
    fileUpdated(event) {
      this.fields.file = event.target.files[0]
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.previewSrc = fileReader.result
      }

      if (this.fields.file.type === 'image/svg+xml') {
        fileReader.readAsText(this.fields.file)
      } else {
        fileReader.readAsDataURL(this.fields.file)
      }
    },
    deleteFile() {
      this.fields.file = null
      this.previewSrc = null
    },
  },
}
</script>
