const statuses = [
  {
    key: 'all',
    name: 'All statuses',
  },
  {
    key: 'published',
    name: 'Published',
  },
  {
    key: 'draft',
    name: 'Draft',
  },
  {
    key: 'to_translate',
    name: 'To translate',
  },
  {
    key: 'to_validate',
    name: 'To validate',
  },
  {
    key: 'unpublished',
    name: 'Unpublished',
  },
]

export default statuses
