<template>
  <select-input
    v-model="lang"
    :values="languageAvailable"
    class="w-full"
    @update:modelValue="switchLang"
  >
    <template #value="{ value }">
      <div class="flex items-center">
        <span class="h-2.5 w-2.5 ml-2 mr-3 rounded-full" :class="`status-${value.status}`" />
        <a class="rounded-t block whitespace-no-wrap">{{ value.label }}</a>
      </div>
    </template>
  </select-input>
</template>

<script>
import languages from '@/config/languagesArticles'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import SelectInput from '@/components/inputs/SelectInput'

export default {
  components: { SelectInput },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const lang = ref(route.params.lang || 'en')

    const languageAvailable = languages
      .filter((language) => language.key !== 'all')
      .map((language) => {
        return {
          label: language.name,
          value: language.key,
          status: store.getters['articles/getById'](props.id, language.key)?.field_statut,
        }
      })

    const switchLang = (newLang) => {
      router.push({
        name: route.name,
        params: { id: props.id, lang: newLang },
      })
    }

    return {
      languageAvailable,
      lang,
      switchLang,
    }
  },
}
</script>
