<template>
  <div
    class="bg-darkgray w-full h-screen fixed top-O left-0 bg-opacity-90"
    style="top: 0; z-index: 99999"
  >
    <div id="wysiwyg-link-selector" v-click-outside="close" class="rounded">
      <div class="container mx-auto">
        <input
          ref="query"
          v-model="query"
          placeholder="Search an article or paste an external link..."
          type="text"
          class="w-full text-darkgray px-3 py-3 rounded-lg focus:outline-none"
          @keyup="updateQuery"
          @paste="setIsLoading(true)"
          @keypress="setIsLoading(true)"
          @keyup.enter="selectUrl"
          @keydown.esc="close"
        />
        <div v-if="isLoading" class="text-center p-4">
          <font-awesome-icon icon="spinner" spin :style="{ color: 'white' }" />
        </div>
        <div v-else-if="items.length" class="bg-darkgray overflow-y-auto max-h-80">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="text-white p-2 hover:bg-gray-700 cursor-pointer flex space-x-4 items-center"
          >
            <div>
              <font-awesome-icon
                title="open link"
                size="2x"
                class="p-1 hover:text-gray-400"
                :icon="fileLink"
                @click="openUrl(item)"
              />
            </div>
            <div @click="selectItem(item)">
              <p class="font-bold">{{ item.title }}</p>
              <!-- <p class="text-gray-200">{{ item.url }}</p> -->
              <p class="text-gray-300">Modified {{ item.changed }}</p>
              <small class="text-gray-400">{{ item.id }}</small>
            </div>
          </div>
        </div>
        <div v-else-if="query && items.length === 0" class="text-center p-8 text-white">
          No results.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { useAxios, useFetch, useCreate } from '@/services/api'

export default {
  name: 'LinkModal',
  emits: ['apply', 'close-modal'],
  data() {
    return {
      query: null,
      items: [],
      isLoading: false,
      fileLink: ['fas', 'link'],
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.focusInput()
    })
  },
  methods: {
    setIsLoading(state) {
      this.isLoading = state
    },
    isExternal() {
      return this.query.startsWith('http')
    },
    openUrl(item) {
      window.open(item.url)
    },
    focusInput() {
      this.$refs.query.focus()
    },
    selectItem(item) {
      const link = this.getOrCreateLink(item).then((link) => {
        this.$emit('apply', {
          slug: 'link',
          payload: {
            id: link.id,
            type: item.type,
            url: `link:${link.id}`,
            name: item.title,
          },
        })
      })
    },
    async selectUrl() {
      // If it's a word don't try to inject it in the article
      if (!this.isExternal()) {
        this.close()

        return
      }

      let link

      this.setIsLoading(true)

      link = await this.getLinkByUrl(this.query)
        .then((data) => {
          return data.data.shift()
        })
        .catch((e) => {
          alert(e)
        })

      // should insert one if null
      if (!link) {
        link = await this.createLink({ url: this.query }).then((data) => {
          return data.data
        })
      }

      this.$emit('apply', {
        slug: 'link',
        payload: {
          id: link.id,
          type: 'link',
          url: `link:${link.id}`,
          name: link.title
            ? link.title
            : link.field_url.title
            ? link.field_url.title
            : link.field_url.uri,
        },
      })
    },
    close() {
      this.$emit('close-modal')
    },
    async getOrCreateLink(item) {
      return await this.getLinkById(item)
        .then((data) => {
          return data.data
        })
        .catch((e) => {
          return this.createLink(item).then((data) => {
            return data.data
          })
        })
    },
    async getLinkByUrl(url) {
      const params = {}

      params.filter = {
        uriFilter: {
          condition: {
            value: url,
            path: 'field_url.uri',
            operator: '=',
          },
        },
      }

      params.page = {
        limit: 1,
      }

      return await useFetch('link', params)
    },
    async getLinkById(item) {
      return await useFetch('link', {}, item.id)
    },
    async createLink(item) {
      return useCreate('link', {
        id: item.id,
        field_url: {
          uri: item.nid ? `entity:node/${item.nid}` : item.url,
          title: item.title,
        },
      })
    },
    updateQuery: debounce(async function () {
      const params = {
        page: {
          limit: 10,
        },
        sort: '-changed',
      }

      let type = 'article'
      let lang = null
      const baseOperator = 'CONTAINS'

      if (this.query) {
        if (!this.isExternal()) {
          lang = this.lang
          params.filter = {
            orGroup: {
              group: {
                conjunction: 'OR',
              },
            },
            titleFilter: {
              condition: {
                path: 'title',
                value: this.query,
                operator: baseOperator,
                memberOf: 'orGroup',
              },
            },
            // Search by Article id
            nidFilter: {
              condition: {
                path: 'drupal_internal__nid',
                value: this.query,
                operator: '=',
                memberOf: 'orGroup',
              },
            },
          }
          params.fields = {
            'node--article': 'id,drupal_internal__nid,title,url,changed,created',
          }
        } else {
          type = 'link'
          params.filter = {
            uriFilter: {
              condition: {
                value: this.query,
                path: 'field_url.uri',
                operator: baseOperator,
              },
            },
          }
        }

        const items = await useFetch(type, params, null, lang)
        this.items = []
        items.data.forEach((v, k) => {
          let o = {
            id: v.id,
            nid: v.drupal_internal__nid ? v.drupal_internal__nid : null,
            type: type,
            title: v.title ? v.title : v.field_url.title ? v.field_url.title : v.field_url.uri,
            url: type === 'article' ? v.url : v.field_url.uri,
            changed: v.changed,
            created: v.created,
          }

          this.items.push(o)
        })
      } else {
        this.items = []
      }
      this.setIsLoading(false)
    }, 600),
  },
}
</script>

<style scoped>
#wysiwyg-link-selector {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 600px;
  z-index: 100;
}
</style>
