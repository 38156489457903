<template>
  <button
    :type="type"
    :disabled="disabled"
    class="
      w-full
      inline-flex
      justify-center
      rounded-md
      px-4
      py-2
      mx-4
      text-base
      font-semibold
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200
      sm:ml-3 sm:w-auto sm:text-sm
      disabled:opacity-50
      focus:outline-none focus:ring-2 focus:ring-offset-2
    "
    :class="{
      'text-white hover:bg-darkgray bg-orange focus:ring-orange': level === 'primary',
      'text-gray-600 hover:bg-gray-50 bg-white focus:ring-gray-600': level === 'tertiary',
    }"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    level: {
      type: String,
      required: false,
      default: 'tertiary',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
}
</script>
