<template>
  <div class="flex flex-col rounded-xl bg-white chart">
    <div class="flex w-full p-5 border-b border-gray-200">
      <slot name="header" />
    </div>
    <div class="w-full h-96 p-8">
      <vue3-chart-js ref="chartRef" type="line" :options="chartOptions" :data="chartData" />
    </div>
  </div>
</template>

<script setup>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { computed, ref, watch, onMounted } from 'vue'
const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        labels: [],
        datasets: [],
      }
    },
  },
})
const chartOptions = computed(() => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 15,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 15,
        },
      },
    },
  }
})
const colors = ['#C9510D', '#3b82f6', '#8B5CF6', '#3E9F2D', '#FCD34D']

const chartData = ref({
  labels: [],
  datasets: [],
})
const chartRef = ref(null)
const updateData = () => {
  chartData.value.labels = props.data.labels
  chartData.value.datasets = props.data.datasets.map((dataset, index) => {
    return {
      data: dataset,
      borderColor: colors[index],
      backgroundColor: colors[index],
      borderWidth: 1,
      pointRadius: 2,
    }
  })

  if (chartRef.value) {
    chartRef.value.update()
  }
}
onMounted(() => updateData)
watch(
  () => props.data,
  () => {
    updateData()
  },
)
watch(
  () => chartRef.value,
  () => {
    updateData()
  },
)
</script>

<style scoped lang="scss">
.chart::v-deep {
  .chart-bg-color-0 {
    background-color: #c9510d;
  }
  .chart-bg-color-1 {
    background-color: #3b82f6;
  }
  .chart-bg-color-2 {
    background-color: #8b5cf6;
  }
  .chart-bg-color-3 {
    background-color: #3e9f2d;
  }
  .chart-bg-color-4 {
    background-color: #fcd34d;
  }
}
</style>
