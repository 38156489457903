import AuthService from '@/services/auth.service'

const initialState = {
  status: { loggedIn: false },
  user: null,
}

const getters = {
  getMe(state) {
    return state.user
  },
  isAdmin(state) {
    return state.user?.roles?.includes('administrator')
  },
  isEditor(state) {
    return state.user?.roles?.includes('editor')
  },
  isTranslator(state) {
    return state.user?.roles?.includes('translator')
  },
}

const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true
    state.user = user
  },
  loginFailure(state) {
    state.status.loggedIn = false
    state.user = null
  },
  logout(state) {
    state.status.loggedIn = false
    state.user = null
  },
}

const actions = {
  login({ commit }, code) {
    return AuthService.login(code).then(
      (user) => {
        commit('loginSuccess', user)
      },
      (error) => {
        commit('loginFailure')

        throw error
      },
    )
  },
  setUser({ commit }, user) {
    commit('loginSuccess', user)
  },
  logout({ commit }) {
    AuthService.logout()
    commit('logout')
  },
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
